/*
------------------------------------------------------------
Project Name : Knack System;
------------------------------------------------------------
@charset "utf-8";


/*
------------------------------------------------------------
Project Name : Knack System;
------------------------------------------------------------
*/
/* Reset 
--------------------------------------------------------------------------------------------------------*/
/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
* {
  outline: none !important;
}

html,
body {
  min-width: 375px;
  min-height: 100vh;
}

body {
  font-size: 16px;
  line-height: 22px;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Poppins", sans-serif;
}

a {
  color: #000;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2em;
  padding: 0;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
  background-color: #ffffff;
}

::-webkit-input-placeholder {
  color: #000;
}

::-moz-placeholder {
  color: #000;
}

:-ms-input-placeholder {
  color: #000;
}

:-moz-placeholder {
  color: #000;
}

::-moz-focus-inner {
  border: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

option:not(:checked) {
  color: black;
}

p {
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
}

body.show-nav {
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  background: #821c6b;
}
::-webkit-scrollbar-thumb {
  background: #fbfbfb;
  border-radius: 3px;
  border: 1px solid #821c6b;
}

.login-wrapper {
  min-height: 100vh;
  background-color: #f5edf4;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.login-container {
  max-width: 1300px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.login-logo {
  display: block;
  height: 100px;
  margin-left: -20px;
}

.login-logo img {
  display: block;
  height: 100%;
  width: 100%;
}

.login-card.MuiPaper-root.MuiCard-root {
  padding: 80px 68px;
  max-width: 505px;
  min-width: 505px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.login-card .MuiCardContent-root {
  padding: 0;
}

.login-card
  .MuiCardContent-root
  .MuiFormControl-root
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiInputAdornment-root
  img {
  display: block;
  height: 16px;
  width: 16px;
}

.login-card
  .MuiCardActions-root
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  color: #9e9e9e;
}

.login-card .card-heading {
  margin-bottom: 16px;
  line-height: 38px;
  font-weight: 500;
}

.login-card .card-subheading {
  margin-bottom: 16px;
  margin-top: -16px;
  line-height: 28px;
  font-weight: 400;
  color: #616161;
}

.login-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}

.login-card .MuiCardActions-root > :not(:first-of-type) {
  margin-left: 0;
}

.login-card .MuiButton-text {
  margin-top: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.login-card .MuiCardActions-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: inherit;
  padding: 0;
}

.login-card .forgot-pwd {
  color: #757575;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.login-card .forgot-pwd::after {
  content: " ";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 0;
  border-radius: 1px;
  background-color: #821c6b;
  transition: all 0.5s ease;
  opacity: 0;
}

.login-card .forgot-pwd:hover:not(:focus, :active) {
  color: #821c6b;
}

.login-card .forgot-pwd:hover:not(:focus, :active)::after {
  opacity: 1;
  width: 100%;
}

.login-card .forgot-pwd:focus,
.login-card .forgot-pwd:active {
  color: #424242;
}

main {
  display: flex;
  background-color: #fafafa;
  position: relative;
}

.MuiButtonBase-root.MuiMenuItem-root {
  padding: 12px 16px;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper
  .MuiButtonBase-root.MuiCheckbox-root {
  margin-right: 4px;
}

header.MuiAppBar-root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 21;
}

.header-logo {
  display: block;
  height: 32px;
  margin-left: 16px;
}

.header-logo img {
  height: 100%;
  width: 100%;
}

.header-logo .desktop {
  display: block;
}

.header-logo .mobile {
  display: none;
}

.btn-menu.MuiIconButton-root {
  padding: 8px;
  margin-left: -8px;
  border-radius: 4px;
}

.btn-menu.MuiIconButton-root:focus,
.btn-menu.MuiIconButton-root:active {
  background-color: rgba(0, 0, 0, 0.04);
}

.btn-menu img {
  display: block;
  height: 24px;
  width: 24px;
}

.btn-profile.MuiButton-root {
  padding: 11px;
  border-radius: 0;
  margin: -11px -24px -11px -11px;
  text-transform: capitalize;
}

.btn-profile.MuiButton-root:hover {
  background-color: rgba(130, 28, 106, 0.08);
}

.btn-profile .profile-info {
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.btn-profile > img {
  display: block;
  height: 48px;
  width: 48px;
  margin-right: 10px;
}

.btn-profile .profile-info > img {
  display: block;
  height: 18px;
  width: 18px;
  margin-left: 7px;
}

.btn-profile .profile-info h5 {
  margin-bottom: 6px;
  color: #424242;
  max-width: 131px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-profile .profile-info h6 {
  color: #757575;
}

.profile-menu .MuiMenu-paper.MuiPopover-paper {
  min-width: 188px;
}

.export-menu .MuiMenu-paper.MuiPopover-paper {
  min-width: 129px;
}

.profile-menu .MuiMenuItem-root:not(:last-of-type),
.export-menu .MuiMenuItem-root:not(:last-of-type) {
  border-bottom: 1px solid #f5f5f5;
}

.profile-menu .MuiMenuItem-root,
.export-menu .MuiMenuItem-root {
  padding: 0;
}

.profile-menu .MuiButtonBase-root.MuiMenuItem-root .MuiButton-root,
.export-menu .MuiButtonBase-root.MuiMenuItem-root .MuiButton-root {
  padding: 13px 16px;
  width: 100%;
  justify-content: flex-start;
}

.profile-menu .MuiMenuItem-root:not(:last-of-type) .MuiButton-root,
.export-menu .MuiMenuItem-root:not(:last-of-type) .MuiButton-root {
  padding-bottom: 13px;
}

.profile-menu .MuiMenuItem-root .MuiButton-root,
.export-menu .MuiMenuItem-root .MuiButton-root {
  color: #616161;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 20px;
  font-size: 14px;
}

.profile-menu .MuiMenuItem-root .MuiButton-root img {
  display: block;
  height: 22px;
  width: 22px;
  margin-right: 8px;
}

.profile-menu .MuiMenuItem-root .MuiButton-root:hover,
.export-menu .MuiMenuItem-root .MuiButton-root:hover {
  background-color: transparent;
}
.MuiButtonBase-root.MuiCheckbox-root::before {
  position: absolute;
  content: " ";
  background-image: url("assets/images/ic-uncheck.svg");
  background-repeat: no-repeat;
  background-position: left center;
  height: 18px;
  width: 18px;
  z-index: 0;
}

.MuiButtonBase-root.MuiCheckbox-root img,
.MuiButtonBase-root.MuiRadio-root img {
  position: relative;
  z-index: 1;
  height: 18px;
  width: 18px;
}

.sidebar {
  flex: 0 0 0;
  position: fixed;
  left: -260px;
  top: 70px;
  bottom: 0;
  max-width: 260px;
  width: 100%;
  transition: all 300ms ease-in-out;
  overflow: auto;
  z-index: 20;
  background-color: #821c6b;
  color: #ffffff;
}

.sidebar-toggle .sidebar {
  left: 0;
}

.sidebar .sidebar-item {
  padding: 12px 15px 11px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  line-height: 24px;
  font-weight: 600;
  color: #fafafa;
}

.sidebar .MuiList-root .MuiListItemButton-root {
  padding: 0;
}

.sidebar .MuiList-root .MuiListItemButton-root:not(:last-of-type) {
  margin-bottom: 4px;
  transition: all 0.3s ease;
}

.sidebar .MuiList-root .MuiListItemButton-root a,
.sidebar .MuiList-root .MuiListItemButton-root .nav-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 12px 25px;
  width: 100%;
}

.sidebar .MuiList-root .MuiListItemButton-root a img,
.sidebar .MuiList-root .MuiListItemButton-root .nav-item img:not(.arrow) {
  display: block;
  height: 22px;
  width: 22px;
  margin-right: 14px;
}

.sidebar .MuiList-root .MuiListItemButton-root a.active {
  border-left: 3px solid #ffffff;
  background-color: rgba(158, 136, 153, 0.3);
  padding-left: 22px;
}
.sidebar .MuiList-root .container {
  transition: all 0.3s ease;
}
.sidebar .MuiList-root .container .MuiCollapse-root .MuiList-root {
  padding-top: 2px;
  padding-bottom: 6px;
  padding-left: 44px;
}
.sidebar .MuiList-root .container .MuiCollapse-root .MuiListItemButton-root {
  padding: 6px 10px 6px 18px;
  opacity: 66%;
  position: relative;
  z-index: 1;
}
.sidebar
  .MuiList-root
  .container
  .MuiCollapse-root
  .MuiListItemButton-root:not(:last-of-type) {
  margin-bottom: 2px;
}
.sidebar
  .MuiList-root
  .container
  .MuiCollapse-root
  .MuiListItemButton-root::before {
  content: " ";
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: transparent;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar
  .MuiList-root
  .container
  .MuiCollapse-root
  .MuiListItemButton-root:hover {
  background: transparent;
  opacity: 100%;
}
.sidebar
  .MuiList-root
  .container
  .MuiCollapse-root
  .MuiListItemButton-root.active {
  opacity: 100%;
}
.sidebar
  .MuiList-root
  .container
  .MuiCollapse-root
  .MuiListItemButton-root.active::before {
  background-color: #fff;
}
.sidebar .MuiList-root .container .MuiCollapse-root .MuiListItemText-root {
  margin: 0;
}
.sidebar
  .MuiList-root
  .container
  .MuiCollapse-root
  .MuiListItemText-root
  .MuiTypography-root {
  line-height: 18px;
}

.sidebar .MuiList-root .expanded {
  background-color: rgba(158, 136, 153, 0.3);
}
.sidebar .MuiList-root .expanded .MuiListItemButton-root {
  margin-bottom: 0;
}
.sidebar .MuiList-root .expanded .MuiListItemButton-root .nav-item {
  border-left: 3px solid #ffffff;
  padding-left: 22px;
}
.sidebar .MuiList-root .expanded .MuiListItemButton-root .nav-item .arrow {
  transform: rotate(-180deg);
}

.sidebar .MuiList-root .MuiListItemButton-root .nav-item .arrow {
  display: block;
  margin-left: auto;
  margin-right: -5px;
  height: 18px;
  width: 18px;
  transition: all 0.3s ease;
}

.sidebar
  .MuiList-root
  .MuiListItemButton-root
  a:hover:not(:focus, :active, .active) {
  background-color: rgba(158, 136, 153, 0.3);
}

.main-content {
  max-width: 100%;
  width: 100%;
  flex: 0 0 100%;
  padding: 95px 30px 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  margin-left: auto;
}

.sidebar-toggle .main-content {
  max-width: calc(100% - 260px);
  width: 100%;
  flex: 0 0 calc(100% - 260px);
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
  min-height: 44px;
}

.content-header .heading {
  font-weight: 500;
}

.counter-card.MuiCard-root {
  padding-top: 29px;
  padding-bottom: 35px;
  position: relative;
  height: 100%;
}

.counter-card.MuiCard-root:hover {
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.2);
}

.counter-card.MuiCard-root h5 {
  color: #616161;
  margin-bottom: 12px;
}

.counter-card.MuiCard-root h1 {
  color: #212121;
  font-weight: 500;
}

.counter-card.MuiCard-root .counter-card-icon {
  position: absolute;
  right: 28px;
  bottom: 25px;
}

.counter-card.MuiCard-root .counter-card-icon img {
  display: block;
  height: 78px;
  width: 78px;
}

.table-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table-card-header h3 {
  font-weight: 500;
  color: #212121;
}

.table-card-header .MuiFormControl-root {
  margin-bottom: 0;
}

.table-card-header > div {
  display: flex;
  column-gap: 18px;
}

.table-card-header > div h5 {
  display: flex;
  align-items: center;
  line-height: 24px;
  font-weight: 500;
  color: #424242;
}

.table-card-header > div h5 span {
  display: block;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 24px;
  font-weight: 400;
  color: #616161;
  margin-left: 2px;
}

.table-card-header > div h5 span.mail-add {
  max-width: 300px;
}

.table-card-header > div h5:not(:first-of-type) {
  margin-left: 14px;
}

.btn-view-all.MuiButtonBase-root {
  min-width: 89px;
}

.search-input .MuiInputBase-input {
  padding-left: 42px;
  background-image: url("assets/images/ic-search.svg");
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-size: 16px 16px;
  font-size: 14px;
  min-width: 221px;
  max-width: 221px;
}

.table-card-header .MuiFormControl-root .MuiInputBase-input {
  padding-top: 14px;
  padding-bottom: 14px;
}

.table-card-header .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 14px;
}

.table-card-header .MuiFormLabel-root.MuiInputLabel-root {
  transform: translate(12px, 14px);
}

.table-card-header .MuiFormLabel-root.MuiInputLabel-root.Mui-focused,
.table-card-header .MuiFormLabel-root.MuiInputLabel-root[data-shrink="true"] {
  transform: translate(12px, -7px) scale(0.75);
}

.table-select .MuiFormControlLabel-root {
  display: flex;
}

.table-select .MuiFormControlLabel-root .MuiFormControlLabel-label {
  line-height: 20px;
}

.table-card-header .table-select {
  min-width: 224px;
  max-width: 224px;
}

.table-card-header .course-attnd {
  min-width: 178px;
  max-width: 178px;
}

.counter-box {
  display: flex;
}

.counter-box .title {
  border: 1px solid #bdbdbd;
  border-right: 0;
  padding: 14px 10px 14px 13px;
  color: #424242;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 4px 0 0 4px;
}

.counter-box .counter {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 14px 13px;
  color: #ffffff;
  border-radius: 0 4px 4px 0;
}

.counter-box.success .counter {
  background-color: #71b259;
}

.counter-box.warning .counter {
  background-color: #d8693a;
}

.counter-box.info .counter {
  background-color: #5a8cd7;
}

.logo-card {
  border-radius: 4px;
  height: 100%;
  min-height: 73px;
  display: block;
}

.logo-card .logo-card-content {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.logo-card.bg-rcwiki {
  background-color: #ffe8f3;
}

.logo-card.bg-rcwiki:hover,
.logo-card.bg-rcwiki:focus,
.logo-card.bg-rcwiki:active {
  background-color: #f2c2d9;
}

.logo-card.bg-nsw {
  background-color: #dbd4ff;
}

.logo-card.bg-nsw:hover,
.logo-card.bg-nsw:focus,
.logo-card.bg-nsw:active {
  background-color: #a89fdb;
}

.logo-card.bg-mailchimp {
  background-color: #ecf5e2;
}

.logo-card.bg-mailchimp:hover,
.logo-card.bg-mailchimp:focus,
.logo-card.bg-mailchimp:active {
  background-color: #d5faac;
}

.logo-card.bg-dropbox {
  background-color: #dde7ff;
}

.logo-card.bg-dropbox:hover,
.logo-card.bg-dropbox:focus,
.logo-card.bg-dropbox:active {
  background-color: #acc4ff;
}

.logo-card.bg-eventbrite {
  background-color: #d9f6fc;
}

.logo-card.bg-eventbrite:hover,
.logo-card.bg-eventbrite:focus,
.logo-card.bg-eventbrite:active {
  background-color: #aee9f6;
}

.logo-card .logo-card-content img {
  display: block;
  height: 34px;
  width: 34px;
  margin-right: 14px;
}

.logo-card .logo-card-content img.nsw-img {
  display: block;
  height: 30px;
  width: auto;
  margin-right: 13.6px;
}

.logo-card .logo-card-content h5 {
  color: #212121;
  max-width: 98px;
  font-weight: 400;
}

.MuiSvgIcon-root.MuiSelect-icon path,
.MuiAutocomplete-popupIndicator .MuiSvgIcon-root path {
  display: none !important;
}

.MuiSvgIcon-root.MuiSelect-icon {
  background-image: url(" assets/images/ic-arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease;
  right: 12px;
  height: 16px;
  width: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.MuiAutocomplete-popupIndicator .MuiSvgIcon-root {
  background-image: url(" assets/images/ic-arrow-down.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease;
  height: 16px;
  width: 16px;
}

.MuiSelect-iconOpen.MuiSvgIcon-root.MuiSelect-icon,
.MuiAutocomplete-popupIndicator .MuiSvgIcon-root[aria-label="Close"] {
  transform: rotate(-180deg) translateY(50%);
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  right: 0 !important;
}
.MuiInputBase-root.MuiOutlinedInput-root
  .MuiAutocomplete-endAdornment
  .MuiButtonBase-root.MuiIconButton-root {
  padding: 12px;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: all 0.2s ease;
}
.MuiAutocomplete-root .MuiFormControl-root {
  margin-bottom: 0px;
}
.table-actions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiTableBody-root .MuiTableRow-root:hover td,
.MuiTableBody-root .MuiTableRow-root:hover th {
  background-color: #fafafa;
}

.MuiTableBody-root .MuiTableCell-root.status div {
  padding: 2px 9px;
  border: 1px solid;
  border-radius: 13px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  margin-top: -2px;
  margin-bottom: -2px;
}

.MuiTableBody-root .MuiTableCell-root.confirmed div {
  width: 83px;
  max-width: 83px;
  margin-left: auto;
  margin-right: auto;
  border-color: #37a90f;
  color: #37a90f;
}

.MuiTableBody-root .MuiTableCell-root.rejected div {
  width: 83px;
  max-width: 83px;
  margin-left: auto;
  margin-right: auto;
  border-color: #f3395a;
  color: #f3395a;
}

.MuiTableBody-root .MuiFormControlLabel-root {
  display: flex;
  margin-right: 0;
}

.MuiTableBody-root .MuiTableCell-root .MuiTypography-root {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 14px;
}

.MuiTableBody-root .MuiTableCell-root a {
  color: #424242;
}

.MuiTableHead-root .MuiTableCell-root .MuiTypography-root {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
}

.table-actions .MuiButtonBase-root.MuiIconButton-root {
  padding: 8px;
  margin-top: -8px;
  margin-bottom: -8px;
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.table-actions .MuiButtonBase-root.MuiIconButton-root img {
  display: block;
  height: 20px;
  width: 20px;
}

.table-actions .MuiButtonBase-root.MuiIconButton-root:hover,
.table-actions .MuiButtonBase-root.MuiIconButton-root:focus {
  border-radius: 50%;
  background-color: #e8e8e8;
}

.common-modal {
  background-color: #ffffff;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.reset-modal {
  max-width: 427px;
}

.location-modal {
  min-width: 600px;
  max-width: 600px;
  width: 100%;
}

.delete-modal {
  min-width: 380px;
  max-width: 496px;
  width: 100%;
}

.terms-modal {
  min-width: 915px;
  max-width: 915px;
  width: 100%;
}

.reminder-modal {
  min-width: 496px;
  max-width: 496px;
}

.common-modal .MuiStack-root {
  padding-top: 0;
  overflow: visible;
}

.common-modal
  .modal-body
  .MuiTypography-root:not(.reminder-modal
    .reminder-text.MuiTypography-root, .btn-course.MuiButton-root
    .MuiTypography-root, .common-modal
    .modal-body
    .MuiTableHead-root
    .MuiTableCell-root
    .MuiTypography-root) {
  color: #424242;
  line-height: 24px;
}

.delete-modal.common-modal .modal-header {
  padding: 12px 12px 11px 24px;
}

.delete-modal.common-modal .modal-header h2 {
  font-weight: 500;
  color: #212121;
}

.delete-modal.common-modal .modal-body {
  padding: 24px;
}

.delete-modal.common-modal .modal-footer {
  padding-top: 24px;
  justify-content: flex-end;
}

.reminder-modal.common-modal .modal-header {
  border-bottom-color: transparent;
  justify-content: flex-end;
}

.reminder-modal.common-modal .modal-body {
  padding: 8px 20px 53px;
  text-align: center;
}

.reminder-modal .reminder-icon img {
  display: block;
  height: 70px;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
}

.reminder-modal .reminder-text.MuiTypography-root {
  letter-spacing: 0.25px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 30px;
  color: #d8693a;
  margin-bottom: 15px;
  font-size: 14px;
}

.reminder-modal .reminder-question.MuiTypography-root {
  letter-spacing: 0.25px;
  max-width: 365px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}

.reminder-modal.common-modal .modal-footer {
  padding: 26px 20px 0;
  justify-content: center;
  background-color: transparent;
  margin-top: 0;
}

.terms-modal .terms-read-only {
  padding: 0 0 18px;
}

.terms-modal.common-modal .modal-footer {
  padding-left: 0;
  padding-right: 0;
}

.terms-read-only .detail-name.MuiTypography-root {
  font-size: 12px;
  line-height: 24px;
  color: #424242;
}

.terms-read-only .detail-score.MuiTypography-root {
  font-weight: 600;
  color: #616161 !important;
  font-size: 16px;
}

.attendance-modal {
  min-width: 819px;
  max-width: 819px;
}

.attendance-modal .modal-body .MuiButtonBase-root.MuiCheckbox-root {
  margin: -7px auto;
}

.attendance-modal.common-modal .modal-footer {
  padding-top: 24px;
}

.date-picker
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiButtonBase-root.MuiIconButton-root {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  margin-left: -8px;
}

.date-picker .MuiInputAdornment-root {
  margin-left: 0;
}

.date-picker .MuiFormLabel-root.MuiInputLabel-root {
  max-width: calc(100% - 40px);
}

.MuiInputBase-root.MuiOutlinedInput-root .calendar-icon img {
  display: block;
  height: 24px;
  width: 24px;
}

.common-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px 11px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #ffffff;
  z-index: 99;
  border-radius: 6px 6px 0 0;
}

.common-modal .modal-header h4 {
  font-weight: 500;
  color: #212121;
}

.common-modal .modal-header .MuiIconButton-root {
  padding: 6px;
}

.common-modal .modal-header .MuiIconButton-root img {
  height: 22px;
  width: 22px;
  display: block;
}

.common-modal .modal-body {
  padding: 24px 24px 6px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 126px);
}

.common-modal .modal-footer {
  padding: 16px 24px 24px;
  display: flex;
  column-gap: 15px;
  position: relative;
  z-index: 10;
  background-color: #ffffff;
  margin-top: -16px;
  border-radius: 0 0 6px 6px;
}

.MuiButton-root.btn-save {
  min-width: 92px;
}

.MuiButton-root.btn-cancel {
  min-width: 84px;
}

.MuiButton-root.btn-yes {
  min-width: 58px;
}

.MuiButton-root.btn-no {
  min-width: 52px;
}

.MuiButton-root.btn-add-small {
  min-width: 62px;
}

.MuiButton-root.btn-continue {
  min-width: 99px;
}

.MuiButton-root.btn-reminder {
  min-width: 103px;
}

.MuiButton-root.btn-save img {
  display: block;
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.btn-add.MuiButton-root {
  min-width: 86px;
}

.btn-add.MuiButton-root img {
  display: block;
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.btn-group {
  display: flex;
  column-gap: 15px;
  padding-top: 6px;
}

.location-modal .MuiInputBase-input.MuiOutlinedInput-input {
  min-height: 96px;
  max-height: 96px;
  overflow: auto !important;
}

.table-card-header .MuiFormControl-root.roles-input {
  min-width: 381px;
}

.MuiTableRow-root.table-sub-row .MuiTableCell-root:first-of-type {
  padding-left: 42px;
}

.MuiTableRow-root.table-sub-row
  .MuiTableCell-root:not(:first-of-type)
  .MuiButtonBase-root.MuiCheckbox-root {
  margin: -7px auto;
}

.MuiTableRow-root.table-sub-row
  .MuiTableCell-root:not(:first-of-type)
  .MuiFormControlLabel-root {
  margin-right: 0;
}

.table-card-header .MuiFormLabel-root.MuiInputLabel-root {
  transform: translate(12px, 14px);
}

.table-card-header .MuiFormLabel-root.MuiInputLabel-root.Mui-focused,
.table-card-header .MuiFormLabel-root.MuiInputLabel-root[data-shrink="true"] {
  transform: translate(12px, -7px) scale(0.75);
}

.MuiTableHead-root .MuiTableCell-root .sorting-icon {
  position: relative;
  width: 9px;
  height: 13.5px;
  margin-left: 20px;
}

.MuiTableHead-root .MuiTableCell-root .sorting-icon:after {
  content: "\a";
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #9e9e9e transparent transparent transparent;
  position: absolute;
  top: 55%;
  opacity: 1;
}

.MuiTableHead-root
  .MuiTableCell-root[aria-sort="descending"]
  .sorting-icon:after {
  border-color: #821c6b transparent transparent transparent;
}

.MuiTableHead-root
  .MuiTableCell-root[aria-sort="ascending"]
  .sorting-icon:before {
  border-color: transparent transparent #821c6b transparent;
}

.MuiTableHead-root .MuiTableCell-root .sorting-icon:before {
  content: "\a";
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #9e9e9e transparent;
  position: absolute;
  bottom: 55%;
  opacity: 1;
}

.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

.custom-rowperpage {
  display: flex;
  align-items: center;
}

.custom-rowperpage span {
  line-height: 14px;
  color: #757575;
}

.custom-rowperpage
  .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 9px 25px 9px 8px;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 15px;
  color: #424242;
}

.custom-rowperpage .MuiSvgIcon-root.MuiSelect-icon {
  background-image: url(" assets/images/ic-arrow-down-pagination.svg");
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease;
  right: 8px;
  height: 5px;
  width: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-rowperpage .MuiInputBase-root.MuiOutlinedInput-root {
  margin-left: 14px;
  margin-right: 20px;
}

.custom-rowperpage .MuiButtonBase-root.MuiPaginationItem-root img {
  display: block;
  height: 20px;
  width: 20px;
}

.pagination-menu .MuiButtonBase-root.MuiMenuItem-root {
  padding: 9px 8px;
  font-size: 14px;
  line-height: 14px;
  color: #424242;
  min-height: 0;
}

.btn-filter.MuiButton-root {
  min-width: 92px;
}

.btn-export.MuiButton-root {
  min-width: 77px;
}

.btn-back.MuiButton-root {
  min-width: 68px;
}

.btn-apply.MuiButton-root {
  min-width: 74px;
}

.btn-clear.MuiButton-root {
  min-width: 72px;
}

.btn-filter.MuiButton-root:hover .active {
  display: block;
}

.btn-filter.MuiButton-root:hover .default {
  display: none;
}

.btn-filter img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.btn-filter .active {
  display: none;
}

.btn-filter .default {
  display: block;
}

.MuiTableBody-root .MuiTableCell-root.future div {
  min-width: 93px;
  max-width: 93px;
  border-color: #37a90f;
  color: #37a90f;
}

.MuiTableBody-root .MuiTableCell-root.active div {
  min-width: 55px;
  margin-left: auto;
  margin-right: auto;
  max-width: 55px;
  border-color: #37a90f;
  color: #37a90f;
}

.MuiTableBody-root .MuiTableCell-root.past div {
  min-width: 81px;
  max-width: 81px;
  border-color: #e7a138;
  color: #e7a138;
}

.MuiTableBody-root .MuiTableCell-root.inactive div {
  min-width: 66px;
  max-width: 66px;
  margin-left: auto;
  margin-right: auto;
  border-color: #e7a138;
  color: #e7a138;
}

.MuiTableBody-root .MuiTableCell-root.withdrawn div {
  min-width: 84px;
  max-width: 84px;
  border-color: #e7a138;
  color: #e7a138;
}

.MuiTableBody-root .MuiTableCell-root.pending div {
  min-width: 68px;
  max-width: 68px;
  border-color: #e7a138;
  color: #e7a138;
  margin-left: auto;
  margin-right: auto;
}

.MuiTableBody-root .MuiTableCell-root.present div {
  min-width: 64px;
  max-width: 64px;
  border-color: #3d86f4;
  color: #3d86f4;
  margin-left: auto;
  margin-right: auto;
}

.MuiTableBody-root .MuiTableCell-root.absent div {
  min-width: 61px;
  max-width: 61px;
  border-color: #f3395a;
  color: #f3395a;
  margin-left: auto;
  margin-right: auto;
}

.MuiTableBody-root .MuiTableCell-root.current div {
  min-width: 101px;
  max-width: 101px;
  border-color: #3d86f4;
  color: #3d86f4;
}

.MuiTableBody-root .MuiTableCell-root.confirmed.status-left div,
.MuiTableBody-root .MuiTableCell-root.withdrawn.status-left div {
  margin-left: 0;
  margin-right: 0;
}

.filter-popover {
  border-radius: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: auto;
  max-width: 420px;
  width: 100%;
  box-shadow: none;
  z-index: 22;
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  overflow: auto;
}

.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 21;
  visibility: hidden;
  transition: all 0.3s ease;
}

.filter-open .filter-overlay {
  opacity: 1;
  visibility: visible;
}

.filter-open {
  overflow: hidden;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px 11px;
  border-bottom: 1px solid #e9e9e9;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 99;
}

.filter-header h4 {
  font-weight: 500;
  color: #212121;
}

.filter-header .MuiIconButton-root {
  padding: 6px;
}

.filter-header .MuiIconButton-root img {
  height: 22px;
  width: 22px;
  display: block;
}

.filter-body {
  padding: 20px 16px 14px;
}

.filter-body .MuiFormControl-root {
  margin-bottom: 16px;
}

.filter-popover .search-input .MuiInputBase-input {
  min-width: 0;
  max-width: none;
}

.filter-select-menu .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
  max-width: 388px;
}

.filter-select-menu
  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper
  .MuiButtonBase-root.MuiMenuItem-root {
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-select-menu-employment-status
  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper
  .MuiButtonBase-root.MuiMenuItem-root {
  max-width: 100%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-details {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-top: 20px;
}

.course-details .MuiBox-root > .MuiTypography-root {
  margin-bottom: 8px;
  color: #424242;
}

.course-details .btn-course-large.MuiButton-root {
  min-width: 64px;
  padding: 6px 5px;
  line-height: 16px;
}

.btn-course.MuiButton-root .MuiTypography-root {
  font-weight: 500;
}

.btn-course.MuiButton-root img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.btn-course.MuiButton-root .default {
  display: block;
}

.btn-course.MuiButton-root .active {
  display: none;
}

.btn-course.MuiButton-root:hover .default {
  display: none;
}

.btn-course.MuiButton-root:hover .active {
  display: block;
}

.MuiDateRangeCalendar-root > div:first-child {
  opacity: 0 !important;
}

.MuiStack-root > .MuiTextField-root {
  min-width: 0 !important;
}

.filter-footer {
  padding: 0 16px 20px;
  display: flex;
  column-gap: 15px;
  justify-content: flex-end;
}

.MuiTableBody-root .MuiTableCell-root .btn-course.MuiButton-root {
  min-width: 64px;
  padding: 5px;
  line-height: 16px;
  margin-top: -5px;
  margin-bottom: -5px;
}

.MuiTableBody-root .MuiTableCell-root .btn-course.MuiButton-root p {
  max-width: 15px;
  line-height: 16px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiTableBody-root .MuiTableCell-root .btn-course.MuiButton-root img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.MuiTableBody-root .MuiTableCell-root .btn-course.MuiButton-root .default {
  display: block;
}

.MuiTableBody-root .MuiTableCell-root .btn-course.MuiButton-root .active {
  display: none;
}

.MuiTableBody-root .MuiTableCell-root .btn-course.MuiButton-root:hover .default,
.MuiTableBody-root
  .MuiTableCell-root
  .btn-course.MuiButton-root:active
  .default {
  display: none;
}

.MuiTableBody-root .MuiTableCell-root .btn-course.MuiButton-root:hover .active,
.MuiTableBody-root
  .MuiTableCell-root
  .btn-course.MuiButton-root:active
  .active {
  display: block;
}

.MuiTableHead-root .MuiTableCell-root .first-session {
  font-weight: 600;
  font-size: 14px;
  line-height: 17.5px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 147px;
}

.educator-type-select .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  max-width: 330px;
}

.MuiButtonBase-root.MuiMenuItem-root .MuiListItemText-root .MuiTypography-root {
  color: #424242;
}

.educator-type-select
  .MuiButtonBase-root.MuiMenuItem-root
  .MuiListItemText-root
  .MuiTypography-root {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.skills-textarea .MuiInputBase-input.MuiOutlinedInput-input {
  min-height: 59px;
  max-height: 59px;
  overflow-y: auto !important;
  white-space: normal;
}

.select .MuiFormLabel-root.MuiInputLabel-root {
  max-width: calc(100% - 42px);
}

.editor-class {
  padding: 16px 12px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}

.toolbar-class.rdw-editor-toolbar {
  margin-bottom: 8px;
  padding: 0;
  border: 0;
}

.toolbar-class .rdw-option-wrapper,
.toolbar-class .rdw-dropdown-wrapper {
  min-width: 32px;
  height: 32px;
  border-color: #bdbdbd;
}

.toolbar-class .rdw-dropdown-wrapper .rdw-dropdown-selectedtext {
  min-width: 38px;
}

.toolbar-class .rdw-block-dropdown {
  width: 118px;
}

.editor-class.rdw-editor-main blockquote {
  margin-left: -5px;
}

.editor-class .DraftEditor-editorContainer {
  border-left: 0;
}

.toolbar-class .rdw-option-wrapper:hover,
.toolbar-class .rdw-dropdown-wrapper:hover {
  box-shadow: none;
  border-color: #616161;
}

.select-input-w-btn {
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
}
.select-input-w-btn :not(.MuiAutocomplete-root) .MuiFormControl-root {
  width: calc(100% - 64px);
}
.select-input-w-btn .btn-icon-details.MuiButton-root.form-btn {
  padding: 14px 12px;
}

.editor-class .public-DraftStyleDefault-block {
  margin: 0;
  color: #424242;
  border-radius: 4px;
}

.editor-class .public-DraftStyleDefault-block > span {
  background-color: transparent !important;
}

.editor-class .public-DraftEditor-content {
  height: 140px;
}

.editor-class h1 {
  font-size: 44px;
}

.editor-class h2 {
  font-size: 24px;
}

.editor-class h3 {
  font-size: 20px;
}

.editor-class h4 {
  font-size: 18px;
}

.editor-class h5 {
  font-size: 16px;
}

.editor-class h6 {
  font-size: 14px;
}

.profile-card.MuiPaper-root.MuiCard-root {
  padding-top: 112px;
  padding-bottom: 112px;
}

.profile-card-container {
  max-width: 786px;
  margin-left: auto;
  margin-right: auto;
}

.profile-card.MuiPaper-root.MuiCard-root
  .textarea-add
  .MuiInputBase-input.MuiOutlinedInput-input {
  min-height: 59px;
  max-height: 59px;
  overflow-y: auto !important;
  white-space: normal;
}

.sco-heading.MuiTypography-root {
  color: #212121;
  margin-bottom: 15px;
  font-weight: 500;
}

.sco-detail-title.MuiTypography-root {
  color: #424242;
  margin-bottom: 4px;
  font-weight: 500;
}

.sco-detail.MuiTypography-root {
  color: #616161;
}

.attnd-heading.MuiTypography-root {
  color: #212121;
  margin-bottom: 20px;
  font-weight: 500;
}

.attendance-group {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  align-items: flex-start;
}

.attendance-group .MuiButton-root.btn-attendance {
  border-color: #bdbdbd;
  padding: 15px 11px;
  line-height: 20px;
  color: #9e9e9e;
  min-width: 156px;
  justify-content: space-between;
  font-size: 16px;
}

.attendance-group .MuiButton-root.btn-attendance.btn-w-info {
  border-bottom-width: 3px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.attendance-group .MuiButton-root.btn-attendance.btn-w-info.success {
  border-color: #37a90f;
}

.attendance-group .MuiButton-root.btn-attendance.btn-w-info.warning {
  border-color: #e7a138;
}

.attendance-group .MuiButton-root.btn-attendance.btn-w-info .data {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: flex-start;
}

.attendance-group .MuiButton-root.btn-attendance.btn-w-info .data .label {
  font-size: 12px;
  line-height: 18px;
}

.attendance-group .MuiButton-root.btn-attendance.btn-w-info .data .value {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.attendance-group
  .MuiButton-root.btn-attendance:hover:not(:focus, :active, .btn-w-info) {
  box-shadow: none;
  color: #9e9e9e;
  border-color: #616161;
}

.attendance-group .MuiButton-root.btn-attendance:focus:not(.btn-w-info) {
  box-shadow: none;
  color: #9e9e9e;
  border-color: #821c6b;
}

.attendance-group
  .MuiButton-root.btn-attendance.btn-w-info:hover:not(:focus, :active) {
  box-shadow: none;
  color: #9e9e9e;
}

.attendance-group .MuiButton-root.btn-attendance:focus {
  box-shadow: none;
  color: #9e9e9e;
  border-color: initial;
}

.form-list .MuiListItem-root {
  padding: 0;
}

.form-list .MuiListItem-root:not(:last-of-type) {
  margin-bottom: 12px;
}

.form-list .MuiButtonBase-root.MuiListItemButton-root {
  padding: 8px 0 7px;
  border-bottom: 1px solid #e0e0e0;
}

.form-list .MuiButtonBase-root.MuiListItemButton-root .MuiTypography-root {
  font-size: 14px;
  color: #424242;
}

.form-list .MuiButtonBase-root.MuiListItemButton-root .MuiListItemText-root {
  margin: 0;
  margin-right: 4px;
}

.form-list .MuiButtonBase-root.MuiListItemButton-root img {
  display: block;
  width: 20px;
  height: 20px;
}

.educators-card-group {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.educators-card {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 16px 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.educators-card-content {
  display: flex;
  flex-direction: column;
  margin-right: 13px;
}
.educators-card .btn-educator {
  min-width: 24px;
  padding: 4px;
}

.educators-card .btn-group {
  margin-left: auto;
  padding-top: 0;
  margin-top: -7px;
  display: flex;
  padding: 3px;
  column-gap: 4px;
  margin-right: -12px;
}

.educators-card .btn-educator img {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 0;
}

.educators-card .btn-close {
  min-width: 24px;
  padding: 4px;
}
.educators-card .btn-close img {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 0;
}

.educators-card .name {
  color: #424242;
  font-weight: 500;
  font-size: 14px;
  word-wrap: break-word;
  word-break: break-all;
}

.educators-card .designation {
  color: #424242;
  font-size: 12px;
}

.educators-card .MuiLink-root {
  text-decoration: none;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

.educators-card .MuiLink-root:hover,
.educators-card .MuiLink-root:focus,
.educators-card .MuiLink-root:active,
.educators-card:hover .MuiLink-root,
.educators-card:focus .MuiLink-root,
.educators-card:active .MuiLink-root {
  text-decoration: underline;
}

.educators-card:hover:not(:focus) {
  border-color: #616161;
}

.educators-card:focus {
  border-color: #821c6b;
}

.educators-card img {
  display: block;
  height: 40px;
  width: 40px;
  margin-right: 14px;
}

.wrapper .MuiStack-root {
  padding-top: 0;
  overflow: visible;
}

.course-info-para.MuiTypography-root {
  color: #616161;
  margin-bottom: 16px;
}

.suggestion-textarea .MuiInputBase-input.MuiOutlinedInput-input {
  min-height: 59px;
  max-height: 59px;
  overflow-y: auto !important;
  white-space: normal;
}

.sco-header .sco-header-btn-group {
  max-width: 442px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-left: 10px;
  justify-content: flex-end;
}

.btn-import-old.MuiButton-root {
  min-width: 192px;
}

.btn-offering.MuiButton-root {
  min-width: 134px;
}

.addsco-modal {
  min-width: 819px;
  max-width: 819px;
}

.common-modal .modal-body .date-label.MuiTypography-root {
  color: #9e9e9e;
  margin-bottom: 16px;
  font-size: 16px;
}

.common-modal .modal-body .datetime-divider.MuiTypography-root {
  font-size: 14px;
  line-height: 36px;
}

.datetime-container {
  display: flex;
  column-gap: 16px;
  align-items: center;
  margin-bottom: 20px;
}

.datetime-container .MuiFormControl-root {
  margin-bottom: 0;
}

.datetime-container .MuiStack-root > .MuiTextField-root {
  min-width: 200px !important;
}

.datetime-container .MuiFormControl-root.select {
  min-width: 146px;
  max-width: 146px;
}

.datetime-container .datetime-input {
  display: flex;
  column-gap: 16px;
}

.checkbox-group {
  margin-bottom: 21px;
  display: flex;
}

.checkbox-group .MuiFormControlLabel-root {
  margin-right: 15px;
  display: flex;
}

.checkbox-group .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #757575;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.upload-group {
  display: flex;
  column-gap: 12px;
  align-items: center;
}

.upload-group .MuiTypography-root.upload-label {
  color: #9e9e9e;
  font-size: 12px;
}

.btn-upload.MuiButton-root {
  min-width: 104px;
}

.btn-upload.MuiButton-root img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.btn-upload.MuiButton-root .default {
  display: block;
}

.btn-upload.MuiButton-root .active {
  display: none;
}

.btn-upload.MuiButton-root:hover .default,
.btn-upload.MuiButton-root:focus .default,
.btn-upload.MuiButton-root:active .default {
  display: none;
}

.btn-upload.MuiButton-root:hover .active,
.btn-upload.MuiButton-root:focus .active,
.btn-upload.MuiButton-root:active .active {
  display: block;
}

.common-modal .child-modal-container .modal-footer {
  padding: 0 0 18px;
}

.repeat-modal {
  min-width: 600px;
  max-width: 600px;
}

.repeat-modal .checkbox-group .MuiFormControlLabel-root {
  margin-right: 19px;
}

.common-modal.repeat-modal .modal-body .date-label.MuiTypography-root {
  margin-bottom: 8px;
}

.repeat-modal .checkbox-group {
  margin-bottom: 24px;
}

.common-modal.repeat-modal
  .modal-body
  .repeat-time-labels
  .date-label.MuiTypography-root {
  margin-bottom: 0;
  min-width: 74px;
}

.repeat-modal .end-datetime {
  display: flex;
  column-gap: 24px;
}

.common-modal.repeat-modal
  .modal-body
  .repeat-time-labels
  .MuiFormControl-root {
  margin-bottom: 0;
}

.common-modal.repeat-modal
  .modal-body
  .repeat-time-labels
  .MuiFormControl-root.select {
  min-width: 140px;
  max-width: 140px;
}

.common-modal.repeat-modal .modal-body .repeat-time-labels .MuiStack-root {
  min-width: 155px;
  max-width: 155px;
}

.repeat-modal .modal-body {
  padding-bottom: 30px;
  padding-top: 20px;
}

.addsco-offering-modal .MuiFormControl-root {
  margin-bottom: 0;
}

.addsco-offering-modal.common-modal
  .modal-body
  .MuiTypography-root:not(.btn-course.MuiButton-root .MuiTypography-root) {
  margin-bottom: 0;
}

.addsco-offering-modal .MuiTypography-root strong {
  font-weight: 500;
}

.addsco-offering-modal .modal-body {
  padding-bottom: 30px;
}

.btn-icon-details.MuiButton-root {
  min-width: 48px;
}

.btn-icon-details.MuiButton-root img {
  height: 22px;
  width: 22px;
}

.btn-icon-details.MuiButton-root .default {
  display: block;
}

.btn-icon-details.MuiButton-root .active {
  display: none;
}

.btn-icon-details.MuiButton-root:hover .default,
.btn-icon-details.MuiButton-root:focus .default,
.btn-icon-details.MuiButton-root:active .default {
  display: none;
}

.btn-icon-details.MuiButton-root:hover .active,
.btn-icon-details.MuiButton-root:focus .active,
.btn-icon-details.MuiButton-root:active .active {
  display: block;
}

.btn-icon-details.MuiButton-root.form-btn {
  height: calc(100% - 24px);
}

.std-attended-modal {
  min-width: 388px;
  max-width: 388px;
}

.std-attended-modal .modal-body {
  max-height: 52vh;
}

.std-attended-modal.common-modal .modal-header {
  padding-top: 24px;
  padding-bottom: 16px;
  border-bottom: 0;
  background-color: #ffffff;
}

.std-attended-modal.common-modal .modal-body {
  padding-top: 0;
  padding-bottom: 24px;
}

.educators-details-modal {
  min-width: 819px;
  max-width: 819px;
}
.educators-details-modal .modal-body {
  padding-bottom: 24px;
}
.educators-details-modal .modal-body .title {
  font-weight: 500;
  color: #424242;
  margin-bottom: 2px;
  word-break: break-all;
}
.educators-details-modal .modal-body .value {
  color: #616161 !important;
  word-wrap: break-word;
}

footer {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

footer p {
  line-height: 21px;
  color: #616161;
}

.MuiPaper-root.MuiAppBar-root.student-header {
  box-shadow: 0px 2px 15px 0px rgba(74, 87, 91, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.MuiToolbar-root.header-container-top {
  padding: 8px 24px;
}

.header-container-top .header-logo {
  margin-left: 0;
}

.header-container-top .MuiTypography-root.header-title {
  font-weight: 600;
  line-height: 36px;
  text-transform: capitalize;
  color: #821c6b;
}

.MuiToolbar-root.header-container-bottom {
  padding: 12px 24px;
  background-color: #821c6b;
}

.header-container-bottom .MuiTypography-root.header-subtitle {
  font-weight: 600;
  line-height: 36px;
  text-transform: capitalize;
  color: #fff;
}

.header-container-bottom .MuiStepper-root {
  width: 262px;
}

.stepper-container,
.finished-step {
  min-height: 100vh;
}

.stepper-container {
  background-color: #f4f4f4;
}

.stepper-content-container {
  max-width: 1180px;
  padding: 200px 20px 11px;
  margin-left: auto;
  margin-right: auto;
}

.stepper-content-container .MuiButtonBase-root.MuiRadio-root {
  margin: 0 8px 0 0;
}

.stepper-content-container .empty-container {
  margin-top: -86px;
  min-height: calc(100vh - 178px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.stepper-content-container .empty-container img {
  display: block;
  height: 108px;
  width: 108px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
}
.stepper-content-container .empty-container h4 {
  font-weight: 500;
  color: #212121;
}

.stepper-btn-group {
  display: flex;
  column-gap: 15px;
  justify-content: flex-end;
  margin-top: 24px;
}

.stepper-card .MuiTypography-root.instruction {
  font-style: italic;
  font-weight: 500;
  line-height: 20px;
  color: #616161;
}

.stepper-card.step-1 .MuiTypography-root.instruction {
  margin-bottom: 32px;
}

.stepper-card .MuiTypography-root.instruction span {
  color: #212121;
}

.stepper-content-container .MuiTypography-root.questions {
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
  text-transform: capitalize;
  color: #212121;
}

.stepper-content-container .MuiTypography-root.questions .required {
  color: #ea2222;
  display: inline-block;
}

.stepper-card .MuiFormControlLabel-root {
  margin-right: 12px;
}

.stepper-card .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #757575;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.stepper-card .MuiFormControlLabel-root {
  margin-bottom: 12px;
}

.stepper-card.step-1 {
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.MuiTypography-root.know-more {
  color: #616161;
}
.MuiTypography-root.know-more:not(:last-of-type) {
  margin-bottom: 8px;
}

.MuiTypography-root.know-more a {
  color: #3097d0;
  text-decoration: underline;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root {
  min-width: 124px;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root img {
  margin-right: 8px;
  height: 16px;
}

.stepper-btn-group .btn-next.MuiButtonBase-root.MuiButton-root {
  min-width: 91px;
}

.stepper-btn-group .btn-next.MuiButtonBase-root.MuiButton-root img {
  margin-left: 8px;
  height: 16px;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root .active {
  display: block;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root .disabled {
  display: none;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root .hovered {
  display: none;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root:hover .active,
.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root:focus .active,
.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root:active .active {
  display: none;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root:hover .disabled,
.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root:focus .disabled,
.stepper-btn-group
  .btn-prev.MuiButtonBase-root.MuiButton-root:active
  .disabled {
  display: none;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root:hover .hovered,
.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root:focus .hovered,
.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root:active .hovered {
  display: block;
}

.stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  border-color: #9e9e9e;
  color: #9e9e9e;
}

.stepper-btn-group
  .btn-prev.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .active {
  display: none;
}

.stepper-btn-group
  .btn-prev.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .hovered {
  display: none;
}

.stepper-btn-group
  .btn-prev.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .disabled {
  display: block;
}

.stepper-card.step-2 .MuiTypography-root.instruction {
  margin-bottom: 24px;
}

.stepper-card.step-2 {
  padding-bottom: 0;
}

.stepper-card.step-3 .MuiTypography-root.instruction {
  margin-bottom: 12px;
}

.stepper-card.step-3 .MuiTypography-root.questions {
  margin-bottom: 2px;
}

.stepper-card.step-4 .MuiFormControl-root.select {
  width: 100%;
  max-width: 348px;
}
.stepper-card.step-4 .MuiTableBody-root th.MuiTableCell-root,
.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .MuiTableBody-root
  th.MuiTableCell-root {
  padding: 8px 17.5px 7px 7.5px;
}

.stepper-card.step-4 .MuiTableBody-root .MuiTableCell-root {
  padding: 16px 12px 15px;
}

.stepper-card.step-4
  .MuiTableBody-root
  .MuiTableCell-root
  .MuiStack-root
  .MuiTypography-root:last-of-type {
  color: #757575;
}

.stepper-card.step-4
  .MuiTableBody-root
  .MuiTableCell-root
  .MuiStack-root.checked
  .MuiTypography-root:last-of-type {
  color: #424242;
}

.stepper-card.step-4
  .MuiTableBody-root
  .MuiTableCell-root
  .MuiStack-root
  .MuiTypography-root:first-of-type {
  color: #424242;
}

.stepper-card.step-4
  .MuiTableBody-root
  .MuiTableCell-root
  .MuiStack-root.checked
  .MuiTypography-root:first-of-type {
  color: #757575;
}

.stepper-card.step-4 .prefer-course-del,
.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .prefer-course-del {
  display: none;
}

.step-4.stepper-card .MuiFormControlLabel-root {
  margin-bottom: 0 !important;
  letter-spacing: 0.4px;
}

.step-4.stepper-card .MuiFormControlLabel-root .MuiFormControlLabel-label {
  overflow: visible;
  white-space: normal;
  word-break: break-word;
}

.finished-step .finished-details-container {
  padding: 187px 20px 36px;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.MuiToolbar-root.header-container-bottom.finished {
  min-height: 60px;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root {
  min-width: 96px;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root img {
  margin-right: 8px;
  height: 16px;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root .active {
  display: block;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root .disabled {
  display: none;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root .hovered {
  display: none;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:hover .active,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:focus .active,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:active .active {
  display: none;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:hover .disabled,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:focus .disabled,
.stepper-btn-group
  .btn-back.MuiButtonBase-root.MuiButton-root:active
  .disabled {
  display: none;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:hover .hovered,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:focus .hovered,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:active .hovered {
  display: block;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  border-color: #9e9e9e;
  color: #9e9e9e;
}

.stepper-btn-group
  .btn-back.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .active {
  display: none;
}

.stepper-btn-group
  .btn-back.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .hovered {
  display: none;
}

.stepper-btn-group
  .btn-back.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .disabled {
  display: block;
}

.finished-step .finished-details-container .stepper-card.finished-details {
  padding-bottom: 0;
}

.finished-step .finished-details-container .MuiTypography-root.title {
  font-weight: 500;
  margin-bottom: 24px;
  text-transform: capitalize;
  color: #212121;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  h4.MuiTypography-root {
  font-weight: 500;
  text-transform: capitalize;
  color: #212121;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .eligibility {
  margin-bottom: 24px;
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .eligibility
  h4.MuiTypography-root {
  margin-bottom: 2px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .eligibility
  h6.MuiTypography-root {
  color: #757575;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only {
  margin-bottom: 24px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .prefer-del {
  margin-bottom: 12px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root {
  padding-left: 19px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root
  .MuiListItem-root
  .MuiListItemText-root
  .MuiTypography-root {
  font-size: 14px;
  line-height: 20px;
  color: #757575;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root
  .MuiListItem-root
  .MuiListItemText-root {
  margin: 0;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root
  .MuiListItem-root:not(:last-of-type) {
  margin-bottom: 12px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root
  .MuiListItem-root::before {
  content: " ";
  position: absolute;
  left: -19px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #821c6b;
  top: 8px;
}

.finished-step {
  background-color: #f5f5f5;
}

.finished-step .finished-container {
  padding: 218px 20px 20px;
  max-width: 784px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.finished-container .icon {
  margin-bottom: 29px;
  display: flex;
  justify-content: center;
}

.finished-container .icon img {
  display: block;
  height: 70px;
  width: 70px;
}

.finished-container .MuiTypography-root.greeting {
  font-weight: 500;
  line-height: 30px;
  color: #219653;
  max-width: 418px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}

.finished-container .MuiTypography-root.greeting-para {
  font-weight: 400;
  color: #616161;
  max-width: 708px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}

.std-details .content-header {
  margin-bottom: 24px;
}

.std-details .MuiCard-root .attnd-body-wrapper {
  overflow-y: auto;
  max-height: 347px;
}

.std-detail-btn-group {
  display: flex;
  column-gap: 12px;
}

.btn-vertical-menu.MuiButtonBase-root.MuiButton-root {
  padding: 10px 16px;
  min-width: 48px;
}
.btn-vertical-menu.MuiButtonBase-root.MuiButton-root img {
  display: block;
  height: 16px;
  width: 16px;
}

.std-details-menu .MuiMenu-paper.MuiPopover-paper {
  max-height: 308px;
  max-width: 233px;
}

.std-details-menu .MuiButtonBase-root.MuiMenuItem-root {
  font-size: 14px;
  line-height: 20px;
  color: #616161;
  column-gap: 8px;
  min-height: 44px;
}
.std-details-menu .MuiButtonBase-root.MuiMenuItem-root:not(:last-of-type) {
  padding-bottom: 11px;
  border-bottom: 1px solid #e0e0e0;
}
.std-details-menu .MuiButtonBase-root.MuiMenuItem-root img {
  display: block;
  height: 20px;
}

.std-details .attnd-heading.MuiTypography-root {
  margin-bottom: 24px;
}

.std-details-card .btn-group {
  padding-top: 0;
}

.std-notes-textarea .MuiInputBase-input.MuiOutlinedInput-input {
  min-height: 60px;
  max-height: 60px;
  overflow-y: auto !important;
  white-space: normal;
}

.btn-icon-details.MuiButton-root.form-btn.std-details-btn {
  padding-left: 12px;
  padding-right: 12px;
  height: 44px;
}
.btn-icon-details.MuiButton-root.form-btn.std-details-btn img {
  display: block;
  height: 16px;
  width: 16px;
}

.common-modal.rejection-modal .modal-body .MuiTypography-root {
  margin-bottom: 10px;
}

.common-modal.rejection-modal .modal-body {
  padding-bottom: 0;
}

.common-modal.rejection-modal
  .MuiFormControl-root.MuiTextField-root.skills-textarea {
  margin-bottom: 0;
}

.login-card.student-login-card .forgot-pwd {
  color: #757575;
}

.login-card.student-login-card .forgot-pwd::after {
  width: 0;
  opacity: 0;
}

.login-card.student-login-card .MuiButton-text {
  margin-top: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  column-gap: 8px;
  text-transform: uppercase;
}
.login-card.student-login-card .MuiButton-text img {
  display: block;
  height: 16px;
}

.login-card .MuiCardActions-root.contact-info {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 16px;
  text-align: center;
}
.login-card .MuiCardActions-root.contact-info .label {
  color: #757575;
}
.login-card .MuiCardActions-root.contact-info .link-list {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  align-items: center;
  row-gap: 8px;
}
.login-card .MuiCardActions-root.contact-info .link-list a {
  column-gap: 8px;
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: #212121;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}
.login-card .MuiCardActions-root.contact-info .link-list a img {
  display: block;
  height: 20px;
  width: 20px;
}

.login-card.MuiPaper-root.MuiCard-root.student-login-card {
  padding: 50px 58px;
}

.approved-modal.common-modal .modal-header {
  border-color: transparent;
}

.approved-modal.common-modal {
  min-width: 370px;
  max-width: 496px;
  width: 100%;
}

.approved-modal.common-modal .modal-body {
  padding-top: 0;
  margin-top: -11.5px;
  padding-bottom: 23px;
  text-align: center;
}

.approved-modal.common-modal .modal-header .MuiIconButton-root {
  margin-left: auto;
}

.approved-modal.common-modal .modal-body .icon {
  margin-bottom: 25px;
}
.approved-modal.common-modal .modal-body .icon img {
  display: block;
  height: 70px;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
}

.approved-modal.common-modal .modal-body .MuiTypography-root.approval {
  color: #219653 !important;
  font-weight: 500;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
}

.approved-modal.common-modal .modal-body .MuiTypography-root.std-name {
  color: #616161 !important;
}
.approved-modal.common-modal .modal-body .MuiTypography-root.std-name span {
  display: inline-block;
  color: #212121 !important;
  line-height: 20px !important;
}

.approved-modal.common-modal .modal-footer {
  justify-content: center;
  padding-bottom: 45.5px;
}

.stdenrol-modal.common-modal {
  min-width: 370px;
  max-width: 819px;
  width: 100%;
}

.stdenrol-modal.common-modal .modal-body {
  padding-bottom: 0;
}

.std-details-modal.common-modal .modal-footer {
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
}

.stdenrol-modal.common-modal.delete-modal .modal-footer {
  justify-content: flex-start;
}

.stdenrol-modal.common-modal .modal-body .stdenrol-staff:not(:last-of-type) {
  margin-bottom: 24px;
}

.stdenrol-modal.common-modal .modal-body .stdenrol-staff h5.MuiTypography-root {
  color: #9e9e9e !important;
  margin-bottom: 8px;
}

.stdenrol-modal.common-modal
  .modal-body
  .stdenrol-staff
  .MuiButtonBase-root.MuiRadio-root {
  margin: 0 8px 0 0;
}

.stdenrol-modal.common-modal
  .modal-body
  .stdenrol-staff
  .MuiFormControlLabel-root {
  margin-right: 16px;
}

.stdenrol-modal.common-modal
  .modal-body
  .stdenrol-staff
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  color: #757575 !important;
}

.stdenrol-detail-content .stdenrol-staff {
  margin-bottom: 24px;
}

.stdenrol-detail-content .stdenrol-staff h5.MuiTypography-root {
  color: #9e9e9e !important;
  margin-bottom: 8px;
}

.stdenrol-detail-content .stdenrol-staff .MuiButtonBase-root.MuiRadio-root {
  margin: 0 8px 0 0;
}

.stdenrol-detail-content .stdenrol-staff .MuiFormControlLabel-root {
  margin-right: 16px;
}

.stdenrol-detail-content
  .stdenrol-staff
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  color: #757575 !important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.stdenrol-detail-content .stdenrol-course {
  margin-bottom: 12px;
}

.stdenrol-detail-content .stdenrol-course h5.MuiTypography-root {
  color: #9e9e9e !important;
  margin-bottom: 8px;
}

.stdenrol-detail-content .stdenrol-course .MuiButtonBase-root.MuiRadio-root {
  margin: 0 8px 0 0;
}

.stdenrol-detail-content .stdenrol-course .MuiFormControlLabel-root {
  display: flex;
  align-items: flex-start;
}

.stdenrol-detail-content .stdenrol-course .MuiFormControlLabel-root,
.stdenrol-detail-content
  .stdenrol-course
  .MuiFormControl-root.MuiTextField-root {
  margin-bottom: 12px;
  margin-right: 0;
}

.stdenrol-detail-content
  .stdenrol-course
  .MuiFormControlLabel-root
  .MuiButtonBase-root.MuiCheckbox-root {
  margin: -4px 8px 0 -7px;
}

.stdenrol-detail-content
  .stdenrol-course
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  color: #757575 !important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.std-details-modal.common-modal {
  min-width: 370px;
  max-width: 828px;
  width: 100%;
}

.common-modal.std-details-modal .modal-body {
  max-height: calc(100vh - 198px);
}

.std-details-modal.common-modal .terms-read-only {
  margin-bottom: 24px;
}

.std-details-modal.common-modal .terms-read-only:last-of-type {
  margin-bottom: 0;
}

.std-details-modal.common-modal .acc-details-container h4.MuiTypography-root {
  font-weight: 500;
  text-transform: capitalize;
  color: #212121;
}

.std-details-modal.common-modal .acc-details-container .eligibility {
  margin-bottom: 24px;
}

.std-details-modal.common-modal
  .acc-details-container
  .eligibility
  h4.MuiTypography-root {
  margin-bottom: 6px;
  color: #212121 !important;
}

.std-details-modal.common-modal
  .acc-details-container
  .eligibility
  h6.MuiTypography-root {
  color: #757575 !important;
  line-height: 20px !important;
}

.std-details-modal.common-modal .acc-details-container .MuiList-root {
  padding-left: 30px;
}

.std-details-modal.common-modal .acc-details-container .MuiListItem-root {
  position: relative;
}
.std-details-modal.common-modal
  .acc-details-container
  .MuiListItem-root:not(:last-of-type) {
  margin-bottom: 12px;
}

.std-details-modal.common-modal .acc-details-container .MuiListItemText-root {
  margin: 0;
}

.std-details-modal.common-modal
  .acc-details-container
  .MuiListItem-root::before {
  background-color: #757575;
  position: absolute;
  content: " ";
  height: 3px;
  width: 3px;
  border-radius: 50%;
  top: 8px;
  left: -15px;
}

.std-details-modal.common-modal
  .acc-details-container
  .MuiListItemText-root
  .MuiListItemText-primary.MuiTypography-root {
  color: #757575 !important;
  line-height: 20px !important;
  font-size: 15px;
}

.common-modal.send-sms-modal {
  max-width: 819px;
  min-width: 350px;
}

.common-modal.send-sms-modal .modal-body {
  padding-bottom: 0;
}

.common-modal.send-sms-modal.delete-modal .modal-footer {
  justify-content: flex-start;
}

.common-modal.send-sms-modal .modal-body .text-w-table.MuiTypography-root {
  margin-bottom: 24px;
}

.common-modal.send-sms-modal
  .modal-body
  .MuiTableCell-root
  .MuiTypography-root {
  margin-bottom: 0;
  line-height: 20px !important;
}

.MuiButtonBase-root.MuiButton-root.btn-add-enrol {
  min-width: 199px;
  column-gap: 8px;
}
.MuiButtonBase-root.MuiButton-root.btn-add-enrol img {
  height: 16px;
  width: 16px;
}
.MuiButtonBase-root.MuiButton-root.btn-add-enrol img.default {
  display: block;
}
.MuiButtonBase-root.MuiButton-root.btn-add-enrol img.active {
  display: none;
}
.MuiButtonBase-root.MuiButton-root.btn-add-enrol:hover img.default,
.MuiButtonBase-root.MuiButton-root.btn-add-enrol:active img.default {
  display: none;
}
.MuiButtonBase-root.MuiButton-root.btn-add-enrol:hover img.active,
.MuiButtonBase-root.MuiButton-root.btn-add-enrol:active img.active {
  display: block;
}

.table-card-header div.table-card-texts {
  flex-direction: column;
  row-gap: 12px;
}

.table-card-texts .MuiTypography-root {
  color: #424242;
}

.MuiTypography-root.note {
  font-size: 13px;
  line-height: 20px;
  font-style: italic;
  color: #616161;
}

.media-upload > .MuiTypography-root {
  color: #212121;
  margin-bottom: 12px;
}

.media-upload .upload {
  display: flex;
  margin-bottom: 24px;
}

.media-upload .upload .MuiButton-root.btn-upload {
  font-size: 16px;
  line-height: 24px;
  padding-top: 15px;
  padding-bottom: 15px;
  column-gap: 6px;
  font-weight: 400;
}
.media-upload .upload .MuiButton-root.btn-upload img {
  display: block;
  height: 16px;
}

.media-upload .upload .MuiTypography-root {
  color: #999;
  padding: 14.5px 24px;
}

.MuiGrid-root.media-consent {
  max-width: 817px;
  margin-bottom: 24px;
}

.MuiGrid-root.media-consent .MuiFormControl-root {
  margin-bottom: 0;
}

.confirmation.MuiTypography-root {
  line-height: 24px;
  color: #9e9e9e;
  margin-bottom: 8px;
}

.media-consent .MuiButtonBase-root.MuiRadio-root {
  margin: 0 8px 0 0;
}

.media-consent .MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: #757575;
  font-size: 14px;
  line-height: 20px;
}

.media-consent .MuiFormControlLabel-root {
  margin-right: 12px;
}

.staff-enroll {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-top: 24px;
  column-gap: 24px;
}

.staff-enroll > .MuiFormControl-root {
  margin-bottom: 0;
  max-width: 348px;
}

.stepper-card .staff-enroll .MuiFormControlLabel-root {
  margin-bottom: 0;
}

.btn-add-enrol-filled.MuiButton-root {
  min-width: 163px;
  column-gap: 8px;
}
.btn-add-enrol-filled.MuiButton-root img {
  display: block;
  height: 16px;
}

.btn-session.MuiButton-root.MuiButtonBase-root {
  border-color: #bdbdbd;
  padding: 7px 11px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2px;
}

.btn-session.MuiButton-root .MuiTypography-root.day-num {
  color: #9e9e9e;
}

.btn-session.MuiButton-root .MuiTypography-root.date {
  color: #424242;
}

.btn-session.MuiButton-root.MuiButtonBase-root:hover:not(:focus, :active) {
  box-shadow: none;
  color: #9e9e9e;
  border-color: #616161;
}

.btn-session.MuiButton-root.MuiButtonBase-root:focus {
  box-shadow: none;
  color: #9e9e9e;
  border-color: #821c6b;
}

.std-educators-card .MuiLink-root {
  color: #757575;
}

.std-details-card.card-first .sco-detail-title.MuiTypography-root {
  margin-bottom: 4px;
}

.std-details-card.card-first .sco-detail.MuiTypography-root {
  margin-bottom: 24px;
}

@media (max-width: 1535px) {
  .login-logo {
    margin-left: 0;
  }
  .MuiTableBody-root .MuiTableCell-root .MuiTypography-root.email {
    max-width: 189px;
  }
  .MuiTableBody-root .MuiTableCell-root .MuiTypography-root.role {
    max-width: 222px;
  }
  .MuiTableBody-root .MuiTableCell-root .MuiTypography-root.location {
    max-width: 372px;
  }
  .std-details .MuiCard-root .attnd-body-wrapper {
    max-height: 370px;
  }
}
@media (max-width: 1399px) {
  .sidebar-toggle .main-content {
    padding: 93px 24px 24px;
    max-width: calc(100% - 244px);
    flex-basis: calc(100% - 244px);
  }
  .sidebar {
    max-width: 244px;
    flex: 0 0 244px;
    left: -244px;
  }
  .sidebar .MuiList-root .MuiListItemButton-root a {
    padding: 12px 20px;
  }
  .sidebar .MuiList-root .MuiListItemButton-root a.active,
  .sidebar .MuiList-root .MuiListItemButton-root a:focus {
    padding-left: 17px;
  }
  .counter-card.MuiCard-root {
    padding-top: 24px;
    padding-bottom: 30px;
  }
  .counter-card.MuiCard-root .counter-card-icon {
    right: 20px;
    bottom: 14px;
  }
  .counter-card.MuiCard-root .counter-card-icon img {
    height: 68px;
    width: 68px;
  }
  .logo-card .logo-card-content img,
  .logo-card .logo-card-content img.nsw-img {
    margin-right: 8px;
  }
  .logo-card .logo-card-content h5 {
    font-size: 14px;
  }
  .logo-card .logo-card-content h5 {
    max-width: 82px;
  }
  .educator-type-select .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    max-width: 270px;
  }
  .sco-detail-title.MuiTypography-root {
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1199px) {
  .login-container {
    max-width: 966px;
  }
  .login-card.MuiPaper-root.MuiCard-root {
    padding: 60px 40px;
    max-width: 408px;
    min-width: 408px;
  }
  .login-card.MuiPaper-root.MuiCard-root.student-login-card {
    padding-left: 32px;
    padding-right: 32px;
  }
  .login-logo {
    height: 90px;
  }
  .btn-profile.MuiButton-root {
    margin: -11px -20px -11px -11px;
  }
  .main-content {
    padding: 90px 20px 24px;
  }
  .counter-card.MuiCard-root h5 {
    margin-bottom: 60px;
  }
  .counter-card.MuiCard-root h1 {
    position: absolute;
    bottom: 32px;
  }
  .counter-card.MuiCard-root .counter-card-icon {
    bottom: 20px;
  }
  .content-header {
    margin-bottom: 16px;
  }
  .table-card-header {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
    margin-bottom: 16px;
  }
  .table-card-header .search-input .MuiInputBase-input {
    min-width: 185px;
    max-width: 221px;
  }
  .educator-type-select .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
    max-width: none;
  }
  .editor-class h1 {
    font-size: 36px;
  }
  .custom-rowperpage .MuiInputBase-root.MuiOutlinedInput-root {
    margin-left: 8px;
    margin-right: 10px;
  }
  .educators-card {
    flex: 0 0 calc(50% - 8px);
    max-width: 100%;
    padding: 15px;
  }
  .upload-group {
    margin-bottom: 24px;
  }
  .sco-header.content-header {
    flex-direction: column;
    row-gap: 20px;
    align-items: flex-start;
  }
  .sco-header .sco-header-btn-group {
    margin-left: 0;
  }
  .educators-card-group {
    gap: 16px;
  }
  .MuiTableBody-root .MuiTableCell-root .MuiTypography-root {
    max-width: 198px;
  }
  .sco-detail-title.MuiTypography-root {
    margin-bottom: 2px;
    font-size: 16px;
    line-height: 22px;
  }
  .finished-step .finished-details-container {
    padding-top: 156px;
  }
  .std-details .MuiCard-root .attnd-body-wrapper {
    max-height: none;
  }
}
@media (max-width: 1023px) {
  .educators-card-group {
    flex-direction: column;
  }
}
@media (max-width: 899px) {
  .login-container {
    justify-content: center;
    row-gap: 40px;
  }
  .login-card.MuiPaper-root.MuiCard-root {
    margin-left: auto;
    margin-right: auto;
    padding: 45px 30px;
    max-width: 446px;
    min-width: 446px;
  }
  .login-card.MuiPaper-root.MuiCard-root.student-login-card {
    padding-left: 30px;
    padding-right: 30px;
  }
  .btn-profile .profile-info h5 {
    margin-bottom: 0;
  }
  .btn-menu.MuiIconButton-root:hover {
    background-color: rgba(130, 28, 106, 0.08);
    border-radius: 4px;
  }
  .sidebar {
    padding: 10px 0 20px;
    top: 0;
    position: fixed;
  }
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .sidebar-toggle .overlay {
    opacity: 1;
    z-index: 11;
  }
  .sidebar-toggle .main-content,
  .main-content {
    flex-basis: 100%;
    max-width: 100%;
    min-height: 100vh;
    padding: 90px 20px 24px;
  }
  .sidebar-toggle {
    overflow: hidden;
  }
  .table-card-header > div {
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
  }
  header.MuiAppBar-root {
    position: fixed;
    z-index: 10;
    top: 0;
  }
  .reset-modal {
    min-width: 340px;
  }
  .location-modal {
    min-width: 500px;
    max-width: 500px;
  }
  .terms-modal,
  .addsco-modal,
  .attendance-modal,
  .educators-details-modal {
    min-width: 700px;
    max-width: 700px;
    width: 100%;
  }
  .filter-popover {
    max-width: 350px;
  }

  .filter-select-menu .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    max-width: 318px;
  }

  .profile-card.MuiPaper-root.MuiCard-root {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .datetime-container .MuiStack-root > .MuiTextField-root {
    min-width: 132px !important;
  }
  .educators-card-group {
    flex-direction: row;
  }
  .datetime-container .MuiFormControl-root.select {
    min-width: 124px;
    max-width: 124px;
  }
  .student-table .table-card-header .MuiFormControl-root {
    margin-bottom: 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 110px);
  }
  .student-table .table-card-header .search-input .MuiInputBase-input {
    min-width: 0;
    max-width: 100%;
    width: 100%;
  }
  .MuiToolbar-root.header-container-top,
  .MuiToolbar-root.header-container-bottom {
    padding-left: 20px;
    padding-right: 20px;
  }
  .stepper-card.step-1 .MuiTypography-root.instruction {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .custom-rowperpage .MuiInputBase-root.MuiOutlinedInput-root {
    margin-left: 6px;
    margin-right: 8px;
  }
  .custom-pagination {
    flex-direction: column;
    row-gap: 20px;
  }
  .terms-modal,
  .addsco-modal,
  .attendance-modal,
  .educators-details-modal {
    min-width: 0;
    max-width: none;
    width: 95%;
  }
  .MuiPickersLayout-contentWrapper {
    max-height: 400px;
    overflow: auto;
  }
  .datetime-container {
    flex-direction: column;
  }
  .datetime-container .datetime-input {
    width: 100%;
  }
  .datetime-container .datetime-input:not(:first-of-type) {
    flex-direction: row-reverse;
  }
  .datetime-container .datetime-input .MuiStack-root {
    width: 100%;
  }
  .datetime-container .MuiFormControl-root.select {
    min-width: 140px;
    max-width: 140px;
  }
  .repeat-modal {
    min-width: 580px;
    max-width: 580px;
  }
  .educators-card-group {
    flex-direction: column;
  }
  .table-card-header .MuiFormControl-root {
    margin-bottom: 0;
    flex: 0 0 calc(50% - 8px);
  }
  .table-card-header .table-select {
    min-width: 0;
    max-width: calc(50% - 8px);
    flex: 0 0 calc(50% - 8px);
  }
  .table-card-header .search-input .MuiInputBase-input {
    min-width: 0;
    max-width: none;
  }
  .common-modal .modal-body .datetime-divider.MuiTypography-root {
    margin: 10px 0;
  }
  .header-container-bottom .MuiTypography-root.header-subtitle {
    font-size: 18px;
    line-height: 28px;
    max-width: 260px;
  }
}
@media (max-width: 599px) {
  .login-card .card-heading {
    font-size: 24px;
  }
  .login-logo {
    height: auto;
    max-width: 100%;
  }
  .login-card.MuiPaper-root.MuiCard-root {
    max-width: none;
    padding-left: 18px;
    padding-right: 18px;
    width: 100%;
    min-width: 0;
  }
  .login-card.MuiPaper-root.MuiCard-root.student-login-card {
    padding-left: 18px;
    padding-right: 18px;
  }
  .login-card .MuiCardActions-root.contact-info {
    margin-top: 8px;
    row-gap: 16px;
    text-align: left;
  }
  .login-card .MuiCardActions-root.contact-info .link-list {
    align-items: flex-start;
  }
  .login-card .MuiCardActions-root.contact-info .link-list a {
    text-align: left;
  }
  .header-logo {
    margin-left: 12px;
  }
  .header-logo .desktop {
    display: none;
  }
  .header-logo .mobile {
    display: block;
  }
  .btn-profile .profile-info {
    display: none;
  }
  .btn-profile.MuiButton-root {
    padding: 5px;
    margin: -5px -14px -5px -5px;
  }
  .profile-menu .MuiMenuItem-root.profile-info {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    cursor: default;
  }
  .profile-menu .MuiMenuItem-root.profile-info h5 {
    color: #424242;
  }
  .profile-menu .MuiMenuItem-root.profile-info h6 {
    color: #757575;
    font-size: 12px;
    line-height: 16px;
  }
  .btn-profile > img {
    margin-right: 0;
  }
  .btn-profile.MuiButton-root:hover {
    background-color: transparent;
  }
  .table-card-header .MuiFormControl-root,
  .table-card-header .table-select {
    flex-basis: 100%;
  }
  .table-card-header .table-select {
    max-width: 100%;
  }
  .sidebar-toggle .main-content,
  .main-content {
    padding: 80px 16px 20px;
  }
  .content-header {
    min-height: 0;
  }
  .reset-modal {
    width: 95%;
  }
  .table-card-header .MuiFormControl-root.roles-input {
    min-width: 0;
    width: 100%;
  }
  .location-modal {
    min-width: 360px;
    max-width: 500px;
    width: 95%;
  }
  .attendance-modal,
  .repeat-modal,
  .educators-details-modal {
    min-width: 0;
    max-width: 500px;
    width: 95%;
  }
  .filter-popover {
    max-width: 100%;
    left: 0;
  }
  .editor-class h1 {
    font-size: 30px;
  }
  .editor-class h2 {
    font-size: 20px;
  }
  .editor-class h3 {
    font-size: 18px;
  }
  .editor-class h4 {
    font-size: 16px;
  }
  .editor-class h5 {
    font-size: 14px;
  }
  .editor-class h6 {
    font-size: 12px;
  }
  .delete-modal {
    min-width: 350px;
    max-width: none;
    width: 95%;
  }
  .reminder-modal {
    min-width: 360px;
    max-width: none;
    width: 95%;
  }
  .reminder-modal.common-modal .modal-body {
    padding-bottom: 20px;
  }
  .course-details {
    flex-direction: column;
    row-gap: 24px;
    align-items: flex-start;
  }
  .table-card-header > div h5:first-of-type {
    margin-right: 10px;
  }
  .table-card-header > div h5:not(:first-of-type) {
    margin-left: 0;
  }
  .profile-card.MuiPaper-root.MuiCard-root {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .MuiTableHead-root .MuiTableCell-root .MuiTypography-root {
    line-height: 17.5px;
  }
  .educators-card {
    flex-basis: 100%;
  }
  .attendance-group,
  .educators-card-group {
    gap: 16px;
  }
  .common-modal .modal-body,
  .common-modal .modal-header,
  .delete-modal.common-modal .modal-body,
  .common-modal .modal-footer {
    padding-left: 16px;
    padding-right: 16px;
  }
  .repeat-modal .checkbox-group {
    flex-wrap: wrap;
    row-gap: 12px;
  }
  .repeat-modal .end-datetime {
    flex-direction: column;
    row-gap: 16px;
  }
  .common-modal .modal-body .datetime-divider.MuiTypography-root {
    margin: 4px 0;
  }
  .common-modal.repeat-modal .modal-body .repeat-time-labels .MuiStack-root,
  .common-modal.repeat-modal
    .modal-body
    .repeat-time-labels
    .MuiFormControl-root.select {
    min-width: 0;
    max-width: none;
  }
  .attendance-group .MuiButton-root.btn-attendance {
    width: 100%;
  }
  .sco-header .sco-header-btn-group {
    max-width: none;
    justify-content: flex-start;
  }
  .sco-header.content-header {
    row-gap: 16px;
  }
  .btn-group {
    padding-top: 0;
  }
  .content-header .heading {
    margin-right: 20px;
  }
  .search-filter-inputs .btn-filter.MuiButton-root img + span {
    display: none;
  }
  .search-filter-inputs .btn-filter.MuiButton-root {
    min-width: 48px;
  }
  .search-filter-inputs .btn-filter.MuiButton-root img {
    margin-right: 0;
  }
  .table-card-header .search-filter-inputs .MuiFormControl-root.search-input {
    flex-basis: calc(100% - 64px);
  }
  .btn-add.MuiButton-root img + span {
    display: none;
  }
  .btn-add.MuiButton-root img {
    margin-right: 0;
  }
  .btn-add.MuiButton-root.MuiButtonBase-root {
    min-width: 0;
    padding-left: 14px;
    padding-right: 14px;
  }
  .btn-offering.MuiButton-root {
    min-width: 113px;
    font-size: 12px;
  }
  .btn-import-old.MuiButton-root {
    min-width: 170px;
    font-size: 12px;
  }
  .sco-header .sco-header-btn-group {
    column-gap: 8px;
  }
  .table-card-header .table-select.course-attnd {
    max-width: calc(100% - 105px);
  }
  .stepper-content-container {
    padding: 220px 14px 30px;
  }
  .MuiToolbar-root.header-container-top {
    justify-content: center;
    row-gap: 4px;
    flex-direction: column;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .header-container-top .MuiTypography-root.header-title {
    line-height: 26px;
  }
  .MuiToolbar-root.header-container-bottom {
    padding: 8px 20px;
    justify-content: center;
    flex-direction: column;
    row-gap: 4px;
  }
  .header-container-bottom .MuiTypography-root.header-subtitle {
    line-height: 24px;
    font-size: 16px;
    max-width: none;
  }
  .MuiTypography-root.know-more {
    font-size: 14px;
    line-height: 20px;
  }
  .stepper-btn-group .btn-prev.MuiButtonBase-root.MuiButton-root {
    min-width: 112px;
  }
  .stepper-content-container .MuiTypography-root.questions {
    line-height: 20px;
  }
  .step-4 .stepper-card.MuiPaper-root.MuiCard-root:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .finished-step .finished-container {
    padding: 158px 16px 20px;
  }
  .header-container-bottom .MuiStepper-root {
    width: calc(100% - 24px);
  }
  .stdenrol-modal.common-modal,
  .approved-modal.common-modal,
  .std-details-modal.common-modal {
    min-width: 358px;
    width: 100%;
    margin: auto 20px;
  }
  .std-details .content-header {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
  }
  .staff-enroll {
    flex-direction: column;
    row-gap: 12px;
    align-items: flex-start;
  }
  .staff-enroll > .MuiFormControl-root {
    max-width: 100%;
  }
  .finished-step .finished-details-container {
    padding-top: 170px;
    padding-left: 16px;
    padding-bottom: 16px;
  }
  .finished-step .finished-details-container .MuiTypography-root.title {
    margin-bottom: 12px;
  }
  .finished-step
    .finished-details-container
    .stepper-card.finished-details
    .terms-read-only {
    margin-bottom: 18px;
  }
  .btn-filter.MuiButton-root.report-filter {
    min-width: 50px;
  }
  .btn-filter.MuiButton-root.report-filter img {
    margin-right: 0;
  }
  .btn-filter.MuiButton-root.report-filter .text {
    display: none;
  }
}


