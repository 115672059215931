.login-content-form .css-ic6eyv-MuiFormHelperText-root {
  margin-left: 0;
}

.loaderHide {
  display: none;
}

.loaderShow {
  display: block;
}

.preloader {
  text-align: center;
}
.preloader.fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.35);
}

.btn-profile > .MuiAvatar-root {
  height: 48px;
  width: 48px;
  margin-right: 10px;
}

.color-red {
  color: red;
}

.MuiTypography-root.no-record-text {
  margin-top: 10px;
  text-align: center;
  height: 30px;
}

.MuiTypography-root.no-record-text .no-record-span {
  line-height: 30px;
}

.disabled-icon {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.hidden-list-item {
  display: none !important;
}

.MuiTableHead-root .MuiTableCell-root.term-sorting .sorting-icon {
  margin-left: 10px;
}

.delete-modal.common-modal .modal-footer {
  padding-top: 24px;
  justify-content: flex-end;
  margin-top: 0;
}

.delete-modal.common-modal .add-enrolment-modal-footer {
  padding-top: 24px;
  padding: 16px 0px 24px;
  display: flex;
  column-gap: 15px;
  position: relative;
  z-index: 10;
  background-color: #ffffff;
  margin-top: -16px;
  border-radius: 0 0 6px 6px;
}

.ellipsis-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.login-card .forgot-pwd {
  color: #821c6b;
}

.login-card .forgot-pwd:hover {
  text-decoration: none;
}

.login-card .forgot-pwd::after {
  opacity: 1;
  width: 100%;
}

.MuiTableHead-root .MuiTableCell-root .sorting-icon {
  cursor: pointer;
}

.MuiTypography-root.address-line-2 {
  font-weight: 500;
  color: #212121;
  margin-bottom: 20px;
}

.datetime-container {
  align-items: flex-start;
}

.common-modal .modal-body .datetime-divider.MuiTypography-root {
  margin-top: 13px;
}

.week-repeat-div {
  display: flex;
}

.MuiTypography-root.week-repeat-text {
  align-self: center;
  margin-bottom: 24px;
  margin-left: 12px;
}

.occurence-div {
  display: flex;
  min-width: 165px;
  max-width: 165px;
}

.common-modal.repeat-modal .modal-body .repeat-time-labels .MuiStack-root {
  min-width: 165px;
  max-width: 165px;
}

.MuiGrid-root .repeat-end-labels {
  align-items: flex-start;
}

.MuiTypography-root.ends-label {
  margin-top: 14px;
}

.repeat-end-labels .css-ic6eyv-MuiFormHelperText-root {
  margin-left: 12px;
}

.datetime-container .MuiStack-root > .MuiTextField-root {
  min-width: 185px !important;
}

.datetime-container .MuiStack-root > .MuiTextField-root.time-picker {
  min-width: 152px !important;
}

.MuiFormHelperText-root.Mui-error.repeat-on-error-text {
  margin-top: -20px;
  margin-bottom: 24px;
}

.time-picker .css-i4bv87-MuiSvgIcon-root {
  fill: #9e9e9e;
}

.full-width-picker {
  width: 100%;
}

.MuiButtonBase-root .MuiButton-root.btn-doc-upload {
  min-width: 20px;
}

.MuiButtonBase-root.MuiIconButton-root.upload-icon {
  padding: 0 0 0 8px;
}

.common-modal {
  margin: auto 0;
  max-height: 95%;
}

.common-modal .modal-body {
  max-height: calc(100vh - 175px);
}

.date-picker.disabled-picker-text-field
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background: white;
}

.date-picker.disabled-picker-text-field.w-100 {
  width: 100%;
}

.date-picker.disabled-picker-text-field
  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: black;
}

.date-picker.disabled-picker-text-field
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.time-picker.disabled-time-picker
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  background: white;
}

.time-picker.disabled-time-picker
  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: black;
}

.time-picker.disabled-time-picker
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: red;
}

.MuiTableBody-root .MuiTableCell-root.active-session div {
  min-width: 55px;
  max-width: 55px;
  border-color: #37a90f;
  color: #37a90f;
}

.MuiTableBody-root .MuiTableCell-root.cancelled-session div {
  min-width: 84px;
  max-width: 84px;
  border-color: #e7a138;
  color: #e7a138;
}

.table-card-header > div h5 span.name-add {
  max-width: 160px;
}

.MuiTableHead-root .MuiTableCell-root .first-session {
  max-width: 200px;
  display: inline-flex;
}

.attendance-group .MuiButton-root.btn-attendance .attendance-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-actions .MuiButtonBase-root.MuiIconButton-root.download-btn:focus {
  background: transparent;
}

.attendance-group .MuiButton-root.btn-attendance .attendance-date-text {
  font-size: 14px;
  font-weight: bold;
}

.position-relative {
  position: relative;
}

.MuiIconButton-root.educator-delete-icon {
  position: absolute;
  top: 0px;
  right: 0px;
}

.MuiIconButton-root .educator-delete-img {
  height: 25px;
  width: 25px;
  margin-right: 0;
}

.educators-card {
  height: 100%;
}

.educators-card a {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.educators-heading.MuiTypography-root {
  margin-bottom: 0px;
}

.MuiTypography-root.no-educators-text {
  margin-top: 10px;
}

.MuiCheckbox-root.stepper-check-course {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.stdenrol-detail-content .stdenrol-staff {
  margin-bottom: 24px;
}

.stdenrol-detail-content .stdenrol-staff h5.MuiTypography-root {
  color: #9e9e9e !important;
  margin-bottom: 8px;
}

.stdenrol-detail-content .stdenrol-staff .MuiButtonBase-root.MuiRadio-root {
  margin: 0 8px 0 0;
}

.stdenrol-detail-content .stdenrol-staff .MuiFormControlLabel-root {
  margin-right: 16px;
}

.stdenrol-detail-content
  .stdenrol-staff
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  color: #757575 !important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.stdenrol-detail-content .stdenrol-course {
  margin-bottom: 12px;
}

.stdenrol-detail-content .stdenrol-course h5.MuiTypography-root {
  color: #9e9e9e !important;
  margin-bottom: 8px;
}

.stdenrol-detail-content .stdenrol-course .MuiButtonBase-root.MuiRadio-root {
  margin: 0 8px 0 0;
}

.stdenrol-detail-content .stdenrol-course .MuiFormControlLabel-root {
  display: flex;
  align-items: flex-start;
}

.stdenrol-detail-content .stdenrol-course .MuiFormControlLabel-root,
.stdenrol-detail-content
  .stdenrol-course
  .MuiFormControl-root.MuiTextField-root {
  margin-bottom: 12px;
  margin-right: 0;
}

.stdenrol-detail-content
  .stdenrol-course
  .MuiFormControlLabel-root
  .MuiButtonBase-root.MuiCheckbox-root {
  margin: -4px 8px 0 -7px;
}
.common-modal.send-sms-modal {
  margin-left: 20px;
  margin-right: 20px;
}
.stdenrol-modal.common-modal {
  min-width: 370px;
  max-width: 620px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.common-modal.apply-course-modal {
  min-width: 360px;
  max-width: 460px;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.MuiTableBody-root .MuiTableCell-root.confirmed div,
.MuiTableBody-root .MuiTableCell-root.approved div {
  width: 83px;
  max-width: 83px;
  margin-left: auto;
  margin-right: auto;
  border-color: #37a90f;
  color: #37a90f;
}

.MuiTableBody-root .MuiTableCell-root.rejected div,
.MuiTableBody-root .MuiTableCell-root.cancelled div {
  width: 83px;
  max-width: 83px;
  margin-left: auto;
  margin-right: auto;
  border-color: #f3395a;
  color: #f3395a;
}

.MuiTableBody-root .MuiTableCell-root.waitlisted div {
  width: 83px;
  max-width: 83px;
  margin-left: auto;
  margin-right: auto;
  border-color: #add8e6;
  color: #add8e6;
}

.MuiTableBody-root .MuiTableCell-root.future div {
  min-width: 93px;
  max-width: 93px;
  border-color: #37a90f;
  color: #37a90f;
}

.MuiTableBody-root .MuiTableCell-root.active div {
  min-width: 55px;
  margin-left: auto;
  margin-right: auto;
  max-width: 55px;
  border-color: #37a90f;
  color: #37a90f;
}

.MuiTableBody-root .MuiTableCell-root.past div {
  min-width: 81px;
  max-width: 81px;
  border-color: #e7a138;
  color: #e7a138;
}

.MuiTableBody-root .MuiTableCell-root.inactive div {
  min-width: 66px;
  max-width: 66px;
  margin-left: auto;
  margin-right: auto;
  border-color: #e7a138;
  color: #e7a138;
}

.MuiTableBody-root .MuiTableCell-root.withdrawn div {
  min-width: 84px;
  max-width: 84px;
  border-color: #aaa045;
  color: #aaa045;
  margin-left: auto;
  margin-right: auto;
}

.MuiTableBody-root .MuiTableCell-root.pending div {
  min-width: 68px;
  max-width: 68px;
  border-color: #e7a138;
  color: #e7a138;
  margin-left: auto;
  margin-right: auto;
}

.MuiTableBody-root .MuiTableCell-root.present div {
  min-width: 64px;
  max-width: 64px;
  border-color: #3d86f4;
  color: #3d86f4;
  margin-left: auto;
  margin-right: auto;
}

.MuiTableBody-root .MuiTableCell-root.absent div {
  min-width: 61px;
  max-width: 61px;
  border-color: #f3395a;
  color: #f3395a;
  margin-left: auto;
  margin-right: auto;
}

.MuiTableBody-root .MuiTableCell-root.current div {
  min-width: 101px;
  max-width: 101px;
  border-color: #3d86f4;
  color: #3d86f4;
}
.generate-certificate-modal {
  min-width: 819px;
  max-width: 819px;
}

@media (max-width: 899px) {
  .generate-certificate-modal {
    min-width: 700px;
    max-width: 700px;
    width: 100%;
  }
  .MuiGrid-root.MuiGrid-item.term-status-grid {
    margin-top: 24px !important;
  }
}

@media (max-width: 767px) {
  .stdenrol-modal.common-modal {
    max-width: 500px;
  }
}

@media (max-width: 599px) {
  .stdenrol-modal.common-modal {
    max-width: 300px;
  }

  .generate-certificate-modal {
    min-width: 0;
    max-width: 500px;
    width: 95%;
  }
}
.stdenrol-detail-content
  .stdenrol-course
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  color: #757575 !important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}
@media (max-width: 767px) {
  .datetime-container {
    align-items: center;
  }

  .common-modal .modal-body .datetime-divider.MuiTypography-root {
    margin-top: 10px;
  }

  .generate-certificate-modal {
    min-width: 0;
    max-width: none;
    width: 95%;
  }

  .addsco-modal,
  .attendance-modal,
  .terms-modal {
    max-width: none;
    min-width: 0;
    width: 95%;
    margin: auto;
  }
}

@media (max-width: 599px) {
  .common-modal {
    margin-left: auto;
    margin-right: auto;
  }
}

.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium.backToEnrolment {
  height: 50px;
  margin-top: 15px;
  color: #821c6b;
  font-size: small;
  background-color: white;
  box-shadow: none;
  border: none;
}

.MuiButton-sizeMedium.backToEnrolment.MuiButtonBase-root.MuiButton-root:hover {
  background-color: #f5edf4;
  box-shadow: none;
  color: #821c6b;
}

.confirm-modal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
  border: none !important;
}

.confirm-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
}

.confirm-submit-footer {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  padding-bottom: 25px;
  gap: 15px;
}

.apply-course-modal-footer {
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  padding-left: 25px;
  gap: 15px;
}

.recaptcha-submit-footer {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  padding: 5px 0px 35px;
  gap: 15px;
}

.stepper-card.step-4 .MuiFormControl-root.select {
  width: 100%;
  max-width: 348px;
}

.step-4.stepper-card .MuiFormControlLabel-root {
  margin-bottom: 24px;
}

.finished-step .finished-details-container {
  padding: 187px 20px 36px;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.MuiToolbar-root.header-container-bottom.finished {
  min-height: 60px;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root {
  min-width: 96px;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root img {
  margin-right: 8px;
  height: 16px;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root .active {
  display: block;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root .disabled {
  display: none;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root .hovered {
  display: none;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:hover .active,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:focus .active,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:active .active {
  display: none;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:hover .disabled,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:focus .disabled,
.stepper-btn-group
  .btn-back.MuiButtonBase-root.MuiButton-root:active
  .disabled {
  display: none;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:hover .hovered,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:focus .hovered,
.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root:active .hovered {
  display: block;
}

.stepper-btn-group .btn-back.MuiButtonBase-root.MuiButton-root.Mui-disabled {
  border-color: #9e9e9e;
  color: #9e9e9e;
}

.stepper-btn-group
  .btn-back.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .active {
  display: none;
}

.stepper-btn-group
  .btn-back.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .hovered {
  display: none;
}

.stepper-btn-group
  .btn-back.MuiButtonBase-root.MuiButton-root.Mui-disabled
  .disabled {
  display: block;
}

.finished-step .finished-details-container .stepper-card.finished-details {
  padding-bottom: 0;
}

.finished-step .finished-details-container .MuiTypography-root.title {
  font-weight: 500;
  margin-bottom: 24px;
  text-transform: capitalize;
  color: #212121;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  h4.MuiTypography-root {
  font-weight: 500;
  text-transform: capitalize;
  color: #212121;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .eligibility {
  margin-bottom: 24px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .eligibility
  h4.MuiTypography-root {
  margin-bottom: 2px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .eligibility
  h6.MuiTypography-root {
  color: #757575;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only {
  margin-bottom: 24px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .prefer-del {
  margin-bottom: 12px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root {
  padding-left: 19px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root
  .MuiListItem-root
  .MuiListItemText-root
  .MuiTypography-root {
  font-size: 14px;
  line-height: 20px;
  color: #757575;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root
  .MuiListItem-root
  .MuiListItemText-root {
  margin: 0;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root
  .MuiListItem-root:not(:last-of-type) {
  margin-bottom: 12px;
}

.finished-step
  .finished-details-container
  .stepper-card.finished-details
  .terms-read-only
  .MuiList-root
  .MuiListItem-root::before {
  content: " ";
  position: absolute;
  left: -19px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #821c6b;
  top: 8px;
}

.content-container .sco-detail-card .MuiStack-root {
  margin-bottom: 24px;
}

.btn-profile .profile-info h5 {
  margin-bottom: 6px;
  color: #424242;
  max-width: 131px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-profile .profile-info h6 {
  color: #757575;
  max-width: 131px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .finished-step .finished-details-container {
    padding-top: 156px;
  }
  .sco-header .sco-details-header-btn-group {
    margin-left: 0;
  }
}

@media (max-width: 599px) {
  .finished-step .finished-details-container {
    padding-top: 170px;
    padding-left: 16px;
    padding-bottom: 16px;
  }

  .finished-step .finished-details-container .MuiTypography-root.title {
    margin-bottom: 12px;
  }

  .finished-step
    .finished-details-container
    .stepper-card.finished-details
    .terms-read-only {
    margin-bottom: 18px;
  }

  .common-modal.users-modal,
  .common-modal.documents-modal {
    min-width: 0;
    max-width: 500px;
    width: 95%;
  }

  .sco-header .sco-details-header-btn-group {
    max-width: none;
    justify-content: flex-start;
    column-gap: 8px;
  }

  .MuiGrid-root.MuiGrid-item.term-end-date-grid {
    margin-top: 24px;
  }
}
.terms-read-only .detail-score.MuiTypography-root {
  font-weight: 600;
  color: #616161 !important;
  font-size: 16px;
  word-wrap: break-word;
}

.date-picker.disabled-picker-text-field > p {
  margin-bottom: 24px;
}

.dropbox-file-upload {
  border: 1px dashed #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
}

.dropbox-container {
  height: 175px;
}

.btn-group-add-document {
  margin-top: 20px;
}

.document-modal-text {
  color: #bdbdbd;
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.document-modal-or {
  justify-content: center;
  display: flex;
  margin-top: 5px;
  color: #757575;
}

.upload-document-btn {
  width: 120px;
  display: flex;
  justify-content: center;
}

.upload-document-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 7px;
}

.document-file-name {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
}

.users-modal .textarea-add .MuiInputBase-input.MuiOutlinedInput-input {
  min-height: 96px;
  max-height: 96px;
  overflow: auto !important;
}

.documents-modal .textarea-add .MuiInputBase-input.MuiOutlinedInput-input {
  min-height: 50px;
  max-height: 50px;
  overflow: auto !important;
}

.users-modal,
.documents-modal {
  min-width: 600px;
  max-width: 600px;
  width: 100%;
}

@media (max-width: 899px) {
  .users-modal,
  .documents-modal {
    min-width: 500px;
    max-width: 500px;
  }
}

.btn-apply-enrol-filled.MuiButton-root {
  min-width: 80px;
  column-gap: 8px;
}
.btn-apply-enrol-filled.MuiButton-root img {
  display: block;
  height: 16px;
}
.media-consent-title {
  display: flex;
  gap: 2px;
}

.selected-courses-box {
  padding: 10px;
  height: auto;
  background-color: #f5f5f5;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skills-textarea.rejection-message-textarea {
  width: 100%;
  background-color: none;
  margin-top: 10px;
  margin-bottom: 0px;
}

.status-course-waitlisted {
  width: 78px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #3d86f4;
  color: #3d86f4;
  padding: 3px;
  border-radius: 13px;
  gap: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.status-course-pending {
  width: 78px;
  height: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #e7a138;
  color: #e7a138;
  padding: 3px;
  border-radius: 13px;
  gap: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.confirmation {
  display: flex;
  gap: 2px;
}

.date-picker > p {
  margin-bottom: 24px;
}

.btn-generate-certificate.MuiButton-root {
  min-width: 181px;
}

.generate-certificate-modal.common-modal .modal-footer {
  padding-top: 24px;
}

.sco-header .sco-details-header-btn-group {
  max-width: 442px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-left: 10px;
  justify-content: flex-end;
}

.generate-certificate-modal-body {
  max-height: 500px !important;
}

.MuiTableBody-root .MuiTableCell-root.status div.enrolment-status {
  margin-left: 0px;
  text-align: center;
}

/* student certificate */

@font-face {
  font-family: "Lucida Calligraphy";
  src: url("./assets//fonts/Lucida Calligraphy/lucida calligraphy italic.ttf")
    format("truetype");
  font-weight: 400;
}

.content-wrapper {
  padding: 28pt 28pt 26pt;
  background-size: 100% 100%;
}

.content-container-wrapper {
  padding: 32pt 6pt 44pt;
  background: url("./assets/images/certificate-template-2.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.content-wrapper .main-heading {
  font-family: "Lucida Calligraphy", "sans-serif";
  font-size: 66pt;
  line-height: 68pt;
  margin-bottom: 16pt;
  color: #7030a0;
}

.content-wrapper h6 {
  font-size: 24pt;
  line-height: 30pt;
  margin-bottom: 24pt;
  color: #000;
  font-weight: 500;
}

.content-wrapper .heading {
  font-size: 56pt;
  line-height: 58pt;
  margin-bottom: 22pt;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}

.content-wrapper .para {
  font-size: 20pt;
  line-height: 26pt;
  margin-bottom: 26pt;
  color: #000;
  max-width: 482pt;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}

.content-wrapper h3 {
  font-size: 46pt;
  line-height: 52pt;
  margin-bottom: 14pt;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
}

.content-wrapper h5 {
  font-size: 30pt;
  line-height: 36pt;
  margin-bottom: 140pt;
  color: #000;
  font-weight: 500;
}

.content-wrapper .info-container {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding-left: 32pt;
  padding-right: 36px;
}

.content-wrapper .info-container .educator-details {
  display: flex;
  column-gap: 32pt;
}

.content-wrapper .info-container p {
  font-size: 12pt;
  line-height: 14pt;
  color: #000;
  font-weight: 700;
}

.content-wrapper .info-container .address {
  max-width: 425pt;
}

.content-wrapper .info-container .educator {
  max-width: 236pt;
}

.wrapper .footer {
  display: flex;
  column-gap: 20pt;
  padding: 0 25pt 20pt;
}

.wrapper .footer img {
  display: block;
  height: 58pt;
}

.modal-body > .modal-font-confirmation {
  color: #6e9a35 !important;
}

.MuiFormHelperText-root.document-type-text-warning {
  color: rgb(239, 142, 15);
}

.sco-details-date-picker > p {
  margin-bottom: 0px !important;
}

.table-actions .MuiButtonBase-root.MuiIconButton-root .action-course-list {
  width: 17px;
  height: 17px;
}

.enrolment-sco-actions {
  align-items: left;
  display: flex;
  justify-content: left;
}

.location-modal .modal-body {
  height: 26vh;
}

.MuiChip-root.blended-course {
  color: #821c6b;
  border: 1px solid #821c6b;
}

.sco-location-menu
  .MuiPaper-root.MuiPopover-paper.MuiMenu-paper
  .MuiButtonBase-root.MuiMenuItem-root {
  word-wrap: break-word;
  text-wrap: balance;
}

.MuiTypography-root.MuiTypography-body2.sco-list-word-wrap {
  white-space: normal;
  word-wrap: break-word;
}
.sco-date-picker.disabled-picker-text-field > p {
  color: #d32f2f;
}

.attnd-body-wrapper .MuiButton-root.btn-student-attendance.btn-w-info {
  border-bottom-width: 3px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.attnd-body-wrapper .MuiButton-root.btn-student-attendance.btn-w-info.present {
  border-color: #37a90f;
}

.attnd-body-wrapper .MuiButton-root.btn-student-attendance.btn-w-info.absent {
  border-color: #f3395a;
}

.not-active a.active {
  border-left: 0px !important;
  background-color: transparent !important;
  padding: 12px 25px !important;
}

@media (max-width: 1399px) {
  .not-active a.active {
    border-left: 0px !important;
    background-color: transparent !important;
    padding: 12px 20px !important;
  }
}

.add-new-enrlmnt .MuiTypography-root.MuiTypography-body1 {
  white-space: normal;
}
